import React, { useEffect, useState } from 'react';
import { PolicyComparisonWizardContainer } from './styles';
import { Lines, Pages, PolicyComparisonWizardProps } from './types';
import SelectFirstPolicy from './pages/SelectFirstPolicy';
import SelectComparison from './pages/SelectComparison';
import { DocumentType } from '../Document';
import { useCookies } from 'react-cookie';
import { analyzePolicy, combineDocuments, updateCoverageLines } from '../../requests';
import SelectName from './pages/SelectName';
import UpsellPolicy from './pages/UpsellPolicy';

const PolicyComparisonWizard: React.FC<PolicyComparisonWizardProps> = ({ handleCancel, rows, onSubmit, alreadySelected, handleFileChange, successfulUploads, agencyId, retrieveDocuments }) => {
    const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
    const [page, setPage] = useState<Pages>(Pages.SELECT_FIRST_POLICY);

    const [chosen, setChosen] = useState<DocumentType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [displayName, setDisplayName] = useState<string | null>(null);
    const [createdFor, setCreatedFor] = useState<string | null>(null);
    const [upsellPolicy, setUpsellPolicy] = useState<string | null>(null);
    const [defaultName, setDefaultName] = useState<string>("");
    const [selected, setSelected] = useState<string[]>([]);
    const [chosenIndex, setChosenIndex] = useState<number>(0);

    useEffect(() => {
        setDefaultName(chosen.reduce((prev, curr) => {
            const str = curr.name.replace(".pdf", "");
            const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
            return `${prev}_${capitalized}`;
        }, "").slice(1, 70) + "_Comparison");
    }, [chosen])

    useEffect(() => {
        setChosen(prev => rows.filter(r => !!prev.find(p => r.instanceId == p.instanceId) && r.category == "policy"));
    }, [rows])

    useEffect(() => {
        setChosen(rows.filter(p => alreadySelected?.includes(p.instanceId ?? "") && p.category == "policy"));
    }, [alreadySelected])

    const headers = [
        {label: "Files"},
        {label: "Status"},
    ]

    const handleSelectNameContinue = (s?: string, createdFor?: string) => {
        setDisplayName(s || defaultName);
        setCreatedFor(createdFor || null);
        if (agencyId == process.env.REACT_APP_AGENCY_VA_ID) setPage(Pages.UPSELL_POLICY);
        else if (chosen.filter(c => (c.status == "uploaded" || c.status == "uploading")).length > 0) {
            setPage(Pages.SELECT_LINES);
        } else {
            onSubmit(chosen, s || defaultName);
        }
    }

    const handleUpsellPolicyContinue = () => {
        if (chosen.filter(c => (c.status == "uploaded" || c.status == "uploading")).length > 0) {
            setPage(Pages.SELECT_LINES);
        } else {
            onSubmit(chosen, displayName || defaultName, createdFor || undefined, upsellPolicy || undefined);
        }
    }

    const handleSelectLines = async (selected: string[]) => {
        setLoading(true);

        let newChosenIndex = chosenIndex;
        await combineDocuments(cookies['user-id'], chosen[newChosenIndex].instanceId ?? "");
        await updateCoverageLines(cookies['user-id'], chosen[newChosenIndex].instanceId ?? "", selected);
        analyzePolicy(cookies['user-id'], "", chosen[newChosenIndex].instanceId ?? "", "internal", true);

        newChosenIndex += 1;
        while (newChosenIndex < chosen.length && chosen[newChosenIndex].status != "uploaded") {
            newChosenIndex += 1;
        }
        if (newChosenIndex >= chosen.length) {
            onSubmit(chosen, displayName || defaultName, createdFor || undefined, upsellPolicy || undefined);
        } else {
            setChosenIndex(newChosenIndex);
            await new Promise(r => setTimeout(r, 150));
        }

        setLoading(false);
    }

    const handleSelectedInitialDocument = (row: DocumentType[] ) => {
        //setSelectedDocuments((prev) => [...prev, ...row]);
        setChosen(row);
    }

    const handleSelectedUpsellPolicy = (s: string) => {
        if (upsellPolicy == s) setUpsellPolicy(null);
        else setUpsellPolicy(s);
    } 

    const handleRemoveFromSelected = (docToRemove: DocumentType) => {
        //setSelectedDocuments((previous) => previous.filter((doc) => doc !== docToRemove));
        setChosen((previous) => previous.filter((doc) => doc !== docToRemove));
    }

    return(
        <PolicyComparisonWizardContainer>
            {page === Pages.SELECT_FIRST_POLICY && 
                <SelectFirstPolicy 
                    rows={rows.filter(d => d.category == "policy")}
                    headers={headers}
                    handleSelect={handleSelectedInitialDocument}
                    handleFileChange={handleFileChange}
                    handleContinue={() => setPage(Pages.SELECT_NAME)}
                    progressBlocked={chosen.length < 2}
                    handleCancel={handleCancel}
                    selected={chosen}
                    handleRemoveFromSelected={handleRemoveFromSelected}
                    successfulUploads={successfulUploads}
                />}
            { page === Pages.SELECT_NAME && 
                <SelectName 
                    defaultName={displayName || defaultName}
                    onBack={() => setPage(Pages.SELECT_FIRST_POLICY)}
                    onComplete={handleSelectNameContinue}
                    handleCancel={handleCancel}
                    documents={chosen}
                    agencyId={agencyId}
                />
            }
            { page === Pages.UPSELL_POLICY && 
                <UpsellPolicy
                    rows={chosen}
                    headers={headers}
                    handleSelect={handleSelectedUpsellPolicy}
                    handleContinue={handleUpsellPolicyContinue}
                    progressBlocked={!upsellPolicy}
                    handleCancel={handleCancel}
                    selected={upsellPolicy || ""}
                    handleRemoveFromSelected={handleRemoveFromSelected}
                />
            }
            { page === Pages.SELECT_LINES && 
                <SelectComparison 
                    onBack={() => setPage(Pages.SELECT_NAME)}
                    policyName={chosen[chosenIndex].name}
                    policyId={chosen[chosenIndex].instanceId}
                    onComplete={handleSelectLines}
                    handleCancel={handleCancel}
                    loading={loading}
                    selected={selected}
                    setSelected={setSelected}
                    documents={rows}
                    handleFileChange={handleFileChange}
                    retrieveDocuments={retrieveDocuments}
                    singlePolicy
                />
            }
        </PolicyComparisonWizardContainer>
    );
}

export default PolicyComparisonWizard;

