import React from 'react';
import { DocumentDescriptionContainer, DocumentIcon, FileSize, TextWrapper, Title } from './styles';
import { DocumentDescriptionProps } from './types';
import Policy from '../../assets/icons/Policy.svg';
import Comparison from '../../assets/icons/Comparison.svg';
import Presentation from "../../assets/icons/Presentation.svg";
import File from '../../assets/icons/File.svg';
import { DocumentType } from '../../types/DocumentTemporary';
import { upperFirst } from 'lodash';

const DocumentDescription: React.FC<DocumentDescriptionProps> = ({ type, title, fileSize }) => {
    const getDocumentIcon = (type) => { 
        switch (upperFirst(type)) {
            case DocumentType.COMPARISON:
              return Comparison;
        
            case DocumentType.POLICY:
              return Policy;
        
            case DocumentType.PROPOSAL:
              return Presentation;
        
            default:
              return File
          }
    }

    return (
        <DocumentDescriptionContainer>
            <DocumentIcon alt={type} src={getDocumentIcon(type)}/>
            {fileSize && <TextWrapper>
                    <Title>{title}</Title>
                    { /*<FileSize>{fileSize}</FileSize> */}
                </TextWrapper>
            }
            {!fileSize && <Title>{title}</Title>}
        </DocumentDescriptionContainer>
    )
}

export default DocumentDescription;