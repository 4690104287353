import styled from "styled-components";
import colors from "../../designSystem/colors";
import sizing from "../../designSystem/sizing";
import { spacing } from "react-select/dist/declarations/src/theme";

export const CardButtonContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${colors.UI.BORDER.STANDARD};
  border-radius: ${sizing.RADIUS.MEDIUM};
  padding: ${sizing.SPACING.DOUBLE_LARGE} ${sizing.SPACING.LARGE};
  cursor: pointer;
  background-color: ${colors.UI.BACKGROUND.STANDARD};

  &:hover {
    background-color: ${colors.UI.BACKGROUND.SELECTED};
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  margin: 0 ${sizing.SPACING.QUAD_LARGE} 0 ${sizing.SPACING.LARGE};
`;

export const Header = styled.h2`
  font-size: ${sizing.TEXT.LARGE};
  font-weight: 500;
  margin: 0 0 ${sizing.SPACING.SMALLEST};
`;

export const Content = styled.p`
  font-size: ${sizing.TEXT.MEDIUM};
  margin: 0;
  color: ${colors.UI.TEXT.MEDIUM_GREY};
`;
