import React, { useState, useRef, useEffect, ReactNode } from "react";

import Document, { DocumentStatus, DocumentType } from "./Document";
import {
  deleteDocument,
  editCoverageEntry,
  getDocumentResults,
  getPresignedUrl,
  retrieveDocClient,
} from "../requests";
import { useCookies } from "react-cookie";
import {
  Viewer,
  Worker,
} from "@react-pdf-viewer/core";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "./base/Button.css";

import "@react-pdf-viewer/highlight/lib/styles/index.css";

import {
  highlightPlugin,
  HighlightArea,
  RenderHighlightsProps,
} from "@react-pdf-viewer/highlight";
import { Trigger } from "@react-pdf-viewer/highlight";
import { isAutoVerified } from "../utils";
import AppHeader from "./Navbar";
import TopBar from "./TopBar";
import CreateProposal from "./CreateProposal";
import PolicyComparisonWizard from "./PolicyComparisonWizard";
import { filterAndMapToProposals } from "../pages/Dashboard/utils";
import Modal from "./Modal";
import { PillType } from "./Pill/types";
import { SubHeader } from "./Header/styles";
import {
  DocumentViewContainer,
  PdfWrapper,
  PdfDataWrapper,
  PdfColumnHeader,
  AnalysisContainer,
  Heading,
  ButtonWrapper,
  PdfPaginationControl,
  KeyWrapper,
  AnalysisWrapper,
} from "./PdfModal/styles";
import Button from "./Button";
import Minus from "../assets/icons/Minus.svg";
import PlusBlack from "../assets/icons/PlusBlack.svg";
import ChevronLeft from "../assets/icons/ChevronLeft.svg";
import ChevronRight from "../assets/icons/ChevronRight.svg";
import Download from "../assets/icons/Download.svg";
import ChevronDown from '../assets/icons/ChevronDown.svg';
import Check from "../assets/icons/Check.svg";
import { upperFirst } from "lodash";
import TextB from "./Text";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { Client } from "../types/Client";
import { Icon } from "./Icon/styles";
import { Tooltip } from "react-tooltip";
import EditableText from "./EditableText";
import { LinkedDocuments } from "./DocumentTable";
import Dropdown from "./Dropdown";
import { LinesWrapper } from "./TopBar/styles";
import { useParams } from "react-router-dom";

export interface ResultWithReference {
  // The generated unique identifier
  id: string;

  // The result content
  coverageName: string;

  coverageType: string;

  coverageLine: string;

  keys: any;

  // The list of highlight areas
  highlightArea: HighlightArea;

  // String of Page Ref information
  pageIndex: string;

  verified?: boolean;
}

const scheduleLineOptions = [
  "form_schedule",
  "umbrella_schedule",
  "vehicle_list",
  "general_mod",
  "farm_schedule",
  "liquor_schedule",
  "inland_schedule",
  "address_list",
  "workers_comp_schedule",
  "garage_addresses",
  "driver_list",
  "contractor_property_list",
];

const PDFModal = ({
  setModalOpen,
  modalOpen,
  documents,
  reports,
  onDocumentView,
  retrieveDocuments,
  setSuccessMessage,
  setErrorMessage,
  clientId,
  setClientId,
  setCreateClientModalOpen,
  setSettingsModalOpen,
  onHome,
  clients,
  createProposalModalOpen,
  setCreateProposalModalOpen,
  comparePolicyModalOpen,
  setComparePolicyModalOpen,
  onCreateProposal,
  onCreateComparison,
  handleFileChange,
  onDownload,
  setDeleting,
  setShowDeleteToast,
  successfulUploads,
  setSuccessfulUploads,
  agencyId
}: {
  setModalOpen: (b: boolean) => void;
  modalOpen: boolean;
  documents: DocumentType[];
  reports: DocumentType[];
  retrieveDocuments: () => void;
  onDocumentView: (d: DocumentType) => void;
  setSuccessMessage: (s: string) => void;
  setErrorMessage: (s: string) => void;
  setComparisonModalOpen: (b: boolean) => void;
  clientId: string;
  setClientId: (s: string) => void;
  setCreateClientModalOpen: (bool: boolean) => void;
  setSettingsModalOpen: (bool: boolean) => void;
  onHome: () => void;
  clients: Client[];
  createProposalModalOpen: boolean;
  comparePolicyModalOpen: boolean;
  setCreateProposalModalOpen: (bool: boolean) => void;
  setComparePolicyModalOpen: (bool: boolean) => void;
  onCreateProposal: (proposals: DocumentType[], displayName: string) => Promise<void>;
  onCreateComparison: (
    initial: DocumentType[],
    displayName: string,
    createdFor?: string,
    upsellPolicy?: string,
  ) => Promise<void>;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent) => void;
  onDownload: (document: DocumentType) => void;
  setDeleting: (b: boolean) => void;
  setShowDeleteToast: (b: boolean) => void;
  successfulUploads: string[];
  setSuccessfulUploads: (s: string[]) => void;
  agencyId: string;
}) => {

  const { documentId } = useParams();
  const [cookies, setCookie, removeCookie] = useCookies(["user-id"]);
  const [page, setPage] = useState<string>("normal");
  const [presignedUrl, setPresignedUrl] = useState<string>("");
  const [resultObjects, setResultObjects] = useState<ResultWithReference[]>([]);
  const [filteredResultObjects, setFilteredResultObjects] = useState<
    ResultWithReference[]
  >([]);
  const [selectedResultId, setSelectedResultId] = useState<string>("");
  const [selectedResult, setSelectedResult] = useState<ResultWithReference[]>(
    []
  );
  const [lineOptions, setLineOptions] = useState<string[]>([]);
  const [selectedLine, setSelectedLine] = useState<string>("All Lines");
  const [refreshEntries, setRefreshEntries] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pdfScale, setPdfScale] = useState<number>(1);
  const [pdfPage, setPdfPage] = useState<number>(1);
  const [selectedAnalaysisPoint, setSelectedAnalaysisPoint] =
    useState<string>("");
  const [periods, setPeriods] = useState<string>("");
  const [document, setDocument] = useState<DocumentType | null>(null);
  const [documentNotFound, setDocumentNotFound] = useState<boolean>(false);

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;
  const zoomPluginInstance = zoomPlugin();
  const { zoomTo } = zoomPluginInstance;

  useEffect(() => {
    async function getClientId() {
      if (documentId) {
        const resp = await retrieveDocClient(cookies['user-id'], documentId, "proposal");
        if (resp.status == 200 && resp.body["client_id"]) {
          setClientId(resp.body["client_id"]);
          localStorage.setItem("selectedClientId", resp.body["client_id"]);
        }
      }
    }

    getClientId();

  }, [documentId]);

  useEffect(() => {
    const doc = documents.find(d => d.instanceId == documentId) ?? null;
    if (doc == null && documents.length > 0) setDocumentNotFound(true);
    else {
      setDocumentNotFound(false);
      setDocument(doc);
    }
  }, [documentId, documents]);

  useEffect(() => {
    if (document?.status == "analyzing_policy") {
      const interval = setInterval(() => {
        setPeriods((prevPeriods) =>
          prevPeriods.length < 3 ? prevPeriods + "." : ""
        );
      }, 500);

      return () => clearInterval(interval);
    } else {
      setPeriods("");
    }
  }, [document?.status]);

  const onModalCloseInternal = () => {
    setPage("normal");
    setPresignedUrl("");
    setSelectedResultId("");
    setSelectedResult([]);
  };

  const deleteDoc = async () => {
    setDeleting(true);
    setShowDeleteToast(true);
    const resp = await deleteDocument(
      cookies["user-id"],
      document?.instanceId ?? "",
      document?.category == "analysis" || document?.category == "comparison",
      document?.category == "proposal"
    );
    if (resp.status == 200) {
      setSuccessMessage("Document deleted successfully.");
      retrieveDocuments();
      setModalOpen(false);
      onModalCloseInternal();
    } else {
      setErrorMessage(
        "Unable to delete the document at this time. Please try again in a minute."
      );
    }

    setDeleting(false);
    onHome();
  };

  const renderHighlights = (props: RenderHighlightsProps) => (
    <div>
      {selectedResult.map((result) => (
        <React.Fragment key={result.id}>
          {/* Check if the highlightArea's pageIndex matches the current pageIndex */}
          {result.highlightArea.pageIndex === props.pageIndex && (
            <div
              style={Object.assign(
                {},
                {
                  background: "yellow",
                  opacity: 0.4,
                },
                // Apply position and dimensions using getCssProperties function
                props.getCssProperties(result.highlightArea, props.rotation)
              )}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });
  const { jumpToHighlightArea } = highlightPluginInstance;

  useEffect(() => {
    const findResultById = (
      results: ResultWithReference[],
      id: string
    ): ResultWithReference | undefined => {
      return results.find((result) => result.id === id);
    };

    async function updateHighlights() {
      const foundResult = findResultById(resultObjects, selectedResultId);
      if (foundResult === undefined) {
        console.log("NOTHING CLICKED, RESULT NOT FOUND");
      } else {
        console.log("FOUND RESULT");
        console.log(foundResult);
        setSelectedResult([foundResult]);
      }
    }

    updateHighlights();

    return () => {};
  }, [resultObjects, selectedResultId]);

  useEffect(() => {
    const autoVerified = isAutoVerified(document?.createdAt);
    const translateResultsToReactInterface = (
      results: any[]
    ): ResultWithReference[] => {
      return results.map((result) => {
        return {
          id: result.coverage_id,
          coverageName: result.coverage_name,
          coverageType: result.coverage_type,
          coverageLine: scheduleLineOptions.includes(result.coverage_line)
            ? "Schedules"
            : result.coverage_line ?? "",
          keys: result.keys,
          highlightArea: {
            height: result.highlight_area.height,
            width: result.highlight_area.width,
            left: result.highlight_area.left,
            top: result.highlight_area.top,
            pageIndex: result.highlight_area.page_index,
          },
          pageIndex: result.page_index,
          verified: autoVerified ? true : result.verified,
        };
      });
    };

    async function retrieveDocumentResults() {
      if (modalOpen) {
        try {
          const resp = await getDocumentResults(
            cookies["user-id"],
            document?.instanceId ?? documentId ?? ""
          );
          const translatedResults = translateResultsToReactInterface(
            resp.body["results"]
          );
          setResultObjects(translatedResults);
          let newLines: string[] = [];
          for (let i = 0; i < translatedResults.length; i++) {
            const newLine = translatedResults[i].coverageLine;
            if (newLine != "" && !newLines.includes(newLine)) {
              newLines.push(newLine);
            }
          }
          setLineOptions(newLines);
        } catch (error) {
          console.error("Failed to retrieve presigned URL:", error);
        }
      }
    }

    retrieveDocumentResults(); // Initial call

    if (refreshEntries) {
      setRefreshEntries(false);
    }

    return () => {};
  }, [
    document,
    documentId,
    document?.instanceId,
    document?.category,
    modalOpen,
    refreshEntries,
  ]);

  useEffect(() => {
    const filtered = resultObjects.filter(
      (r) =>
        (r.coverageLine == selectedLine) ||
        (r.coverageType == "generic" && selectedLine == "All Lines")
    )
    const statusOrder = ["generic", "coverage", "endorsement", "schedule"];
    const sorted = filtered.sort((a, b) => {
      return statusOrder.indexOf(a.coverageType) - statusOrder.indexOf(b.coverageType);
    })
    setFilteredResultObjects(sorted);

  }, [resultObjects, selectedLine])

  useEffect(() => {
    let intervalId;

    async function retrievePresignedUrl() {
      if (modalOpen) {
        try {
          const resp = await getPresignedUrl(
            cookies["user-id"],
            document?.link ?? "",
            document?.instanceId ?? "",
            document?.category == "comparison" ||
              document?.category == "analysis",
            document?.category == "proposal"
          );
          setPresignedUrl(resp.body["url"]);
        } catch (error) {
          console.error("Failed to retrieve presigned URL:", error);
        }
      }
    }

    retrievePresignedUrl(); // Initial call

    if (modalOpen) {
      intervalId = setInterval(retrievePresignedUrl, 59 * 60 * 1000); // Set interval for 59 minutes
    }

    return () => {
      clearInterval(intervalId); // Clear interval on component unmount or dependencies change
    };
  }, [document, document?.instanceId, document?.category, modalOpen]);

  const goToPage = (pageIndex: number) => {
    setPdfPage((previous) => {
      const newPage = previous + pageIndex;
      if (newPage <= 0) {
        return previous;
      }
      if (newPage >= totalPages) {
        return previous;
      }
      jumpToPage(newPage);
      return newPage;
    });
  };

  const setScale = (scale: number) => {
    const percentage = scale / 10;
    setPdfScale((previous) => {
      const newScale = Math.round((previous + percentage) * 10) / 10;
      if (newScale < 0.5) {
        return previous;
      }
      if (newScale > 3) {
        return previous;
      }
      zoomTo(newScale);
      return newScale;
    });
  };

  const handleDocumentLoad = (e) => {
    setTotalPages(e.doc.numPages);
  };

  const handleReportDownload = () => {
    const report = reports.find(
      (r) =>
        r.instanceIds &&
        r.instanceIds.length == 1 &&
        r.instanceIds.includes(document?.instanceId ?? "")
    );
    report && onDownload(report);
  };

  const handleUpdateAnalysis = async (text: string, key: string, result: ResultWithReference) => {
    if (key == "value") {
      await editCoverageEntry(cookies['user-id'], result.id, { 'value': text }, result.coverageName);
    } else if (key == "limit") {
      await editCoverageEntry(cookies['user-id'], result.id, { 'limit': text, 'retention': result.keys["retention"] }, result.coverageName);
    } else if (key == "retention") {
      await editCoverageEntry(cookies['user-id'], result.id, { 'limit': result.keys["limit"], 'retention': text }, result.coverageName);
    } else if (key == "description") {
      await editCoverageEntry(cookies['user-id'], result.id, { 'description': text }, result.coverageName);
    } else if (key == "schedule") {
      await editCoverageEntry(cookies['user-id'], result.id, { 'schedule': text }, result.coverageName);
    }

    setRefreshEntries(true);    
  };

  const mapLinesToDropdownOption = () => {
    const options = lineOptions!.map((line) => {
        return {label: line, onClick:(() => setSelectedLine!(line))} 
    })

    options.unshift({ label: "All Lines", onClick: (() => setSelectedLine!("All Lines")) });

    return options;
  }

  return (
    <>
      <AppHeader
        clientId={clientId}
        setClientId={setClientId}
        clients={clients}
        setCreateClientModalOpen={setCreateClientModalOpen}
        setSettingsModalOpen={setSettingsModalOpen}
        onHome={onHome}
        document={document ? document : undefined}
        handleFileChange={handleFileChange}
        retrieveDocuments={retrieveDocuments}
      />
      <TopBar
        onBack={onHome}
        handleCreateProposal={() => setCreateProposalModalOpen(true)}
        handleComparePolicies={() => setComparePolicyModalOpen(true)}
        document={document ? document : undefined}
        download={onDownload}
        deleteDoc={() => deleteDoc()}
        hideModals={document?.category != "policy"}
      />
      { documentNotFound && (
        <div style={{ fontSize: "30px", display: 'flex', alignItems: 'center', justifyContent: 'center', width: "100%", height: "70vh"}}>
          404: Document Not Found
        </div>
      )}
      { !documentNotFound && (<DocumentViewContainer>
        <PdfWrapper id="analysistour-step3">
          <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", maxWidth: "1200px" }}>
            <div style={{ fontSize: "20px", fontWeight: 600 }}>Linked Documents: </div>
            <LinkedDocuments row={document} documents={documents} onDocumentView={onDocumentView} showAll/>
          </div>
          { (document?.category == "policy" || document?.category == "endorsement") && <PdfColumnHeader>
            <PdfPaginationControl>
              <ButtonWrapper>
                <Button iconLeft={Minus} handleClick={() => setScale(-1)} />
                <Button iconLeft={PlusBlack} handleClick={() => setScale(1)} />
              </ButtonWrapper>
              <ButtonWrapper>
                <Button
                  iconLeft={ChevronLeft}
                  handleClick={() => goToPage(-1)}
                />
                <Button
                  iconLeft={ChevronRight}
                  handleClick={() => goToPage(+1)}
                />
              </ButtonWrapper>
            </PdfPaginationControl>
          </PdfColumnHeader>
          }
          { (document?.category == "policy" || document?.category == "endorsement") && (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              {presignedUrl && (
                <div
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    height: "100%",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <Viewer
                    fileUrl={presignedUrl}
                    plugins={[
                      highlightPluginInstance,
                      pageNavigationPluginInstance,
                      zoomPluginInstance,
                    ]}
                    onDocumentLoad={handleDocumentLoad}
                  />
                </div>
              )}
            </Worker>
          )}
          { document?.category == "proposal" && (
            <iframe
              src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(presignedUrl)}`}
              style={{ marginTop: "30px" }}
              width="100%"
              height="70%"
              allowFullScreen
              title="PowerPoint Presentation"
            ></iframe>
          )}
        </PdfWrapper>

        { document?.category == "policy" && 
        <PdfDataWrapper id="analysistour-step4">
          <PdfColumnHeader>
            <SubHeader>
              Policy Analysis{" "}
              {document?.status == "analyzing_policy"
                ? `(Generating${periods})`
                : ""}
            </SubHeader>
            {/*document?.status == "analyzed" && (
              <CircularProgressWithLabel
                value={document?.percentVerified ?? 0}
              />
            )*/}
            {document?.status == "analyzed" && (
              <Button id="analysistour-step7" iconLeft={Download} handleClick={handleReportDownload} text="Download Excel Summary" />
            )}
          </PdfColumnHeader>

          <AnalysisWrapper>
            {lineOptions && <LinesWrapper id="analysistour-step6">
                <Dropdown options={mapLinesToDropdownOption()} text={selectedLine} $grow iconRight={ChevronDown}/>
            </LinesWrapper>}
            {filteredResultObjects.length > 0 &&
              filteredResultObjects.map((analysisPoint, index) => (
                <AnalysisContainer
                  id={index == 0 ? "analysistour-step5" : undefined}
                  key={analysisPoint.id}
                  onClick={() => {
                    jumpToHighlightArea(analysisPoint.highlightArea);
                    setSelectedResultId(analysisPoint.id);
                  }}
                >
                  <Heading
                    selected={analysisPoint.id === selectedAnalaysisPoint}
                    onClick={() => setSelectedAnalaysisPoint(analysisPoint.id)}
                  >
                    {analysisPoint.coverageName}
                    {analysisPoint.verified && (
                      <>
                        <Tooltip
                          id="verification-tooltip"
                          place="top"
                          style={{
                            maxWidth: "250px",
                            whiteSpace: "normal",
                            fontSize: "12px",
                          }}
                        />
                        <Icon
                          data-tooltip-id="verification-tooltip"
                          data-tooltip-content="Manually verified by a human"
                          src={Check}
                          alt="input icon"
                          height="18px"
                        />
                      </>
                    )}
                  </Heading>
                  {Object.keys(analysisPoint.keys).map((key) => (
                    <KeyWrapper>
                      {analysisPoint.coverageType == "coverage" && <TextB content={`${upperFirst(key)}: `} /> }
                      <EditableText
                        defaultValue={analysisPoint.keys[key]}
                        onSubmitChange={(text) => handleUpdateAnalysis(text, key, analysisPoint)}
                        markdown={key == "description" || key == "schedule"}
                        notEditable={key == "schedule"}
                        marginLeft={
                          key == "schedule" 
                            ? "10px" 
                            : key == "value"
                            ? "-10px"
                            : undefined
                        }
                      />
                    </KeyWrapper>
                  ))}
                </AnalysisContainer>
              ))}
          </AnalysisWrapper>
        </PdfDataWrapper>
        }
      </DocumentViewContainer>
      )}
      {createProposalModalOpen && (
        <Modal onClick={() => {
          setCreateProposalModalOpen(false);
          setSuccessfulUploads([]);
        }}>
          <CreateProposal
            proposals={filterAndMapToProposals(documents, PillType.POLICY)}
            alreadySelected={
              document?.instanceId ? [document.instanceId] : undefined
            }
            close={() => setCreateProposalModalOpen(false)}
            onSubmit={onCreateProposal}
            handleFileChange={handleFileChange}
            agencyId={agencyId}
            successfulUploads={successfulUploads}
          />
        </Modal>
      )}

      {comparePolicyModalOpen && (
        <Modal onClick={() => {
          setComparePolicyModalOpen(false);
          setSuccessfulUploads([]);
        }}>
          <PolicyComparisonWizard
            handleFileChange={handleFileChange}
            alreadySelected={
              document?.instanceId ? [document.instanceId] : undefined
            }
            rows={documents}
            handleCancel={() => setComparePolicyModalOpen(false)}
            onSubmit={onCreateComparison}
            successfulUploads={successfulUploads}
            agencyId={agencyId}
          />
        </Modal>
      )}
    </>
  );
};

export default PDFModal;
