import colors from "../../designSystem/colors";

export const getColorFromCount = (
  num: number,
  attribute: "background" | "text" = "background"
) => {
  if (num === 0) {
    return colors.UI[attribute.toUpperCase()].SUCCESS;
  } else if (num > 0 && num < 10) {
    return colors.UI[attribute.toUpperCase()].DANGER;
  } else if (num >= 10) {
    return colors.UI[attribute.toUpperCase()].DANGER;
    // return colors.UI[attribute.toUpperCase()].WARNING;
  } else {
    return colors.UI[attribute.toUpperCase()].STANDARD;
  }
};
