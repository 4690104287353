import styled from "styled-components";
import colors from "../../designSystem/colors";
import { RoundButtonStyleProps } from "./types";

export const RoundButtonContainer = styled.div<RoundButtonStyleProps>`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  border: 1px solid ${colors.UI.BORDER.STANDARD};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: none;
  padding: none;
  cursor: pointer;

  ${({ rotate }) => (rotate ? `transform: rotate(${rotate}deg)` : "")}
`;
