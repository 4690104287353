import { MappableType, PillType, PillTypeIcon } from "./types";
import COLORS from "../../designSystem/colors";
import { IconContainer } from "./styles";
import { DocumentType, DocumentStatus } from "../../types/DocumentTemporary";
import Processed from "../../assets/icons/FileScan.svg";
import Processing from "../../assets/icons/FileSearch.svg";
import FileText from "../../assets/icons/FileText.svg";
import ProposalsIcon from "../../assets/icons/Proposals.svg";
import Files from "../../assets/icons/Files.svg";
import File from "../../assets/icons/File3.svg";

const normalizedType = (type: string) =>
  type.toUpperCase().replace(/[^a-zA-Z_]/g, "");

export const getPillStyles = (type: string) => {
  return (
    COLORS.PILL_TYPE_COLORS[normalizedType(type)] ||
    COLORS.PILL_TYPE_COLORS.DEFAULT
  );
};

export const renderIcon = (type: string) => {
  const normalized = normalizedType(type);
  //if (!Object.keys(PillTypeIcon).includes(normalized)) { return null; }

  let src: string;

  switch (type) {
    case PillType.ANALYZED:
    case PillType.GENERATED:
      src = `${Processed}`;
      break;
    case PillType.ANALYZING:
    case PillType.ANALYZING_POLICY:
      src = `${Processing}`;
      break;
    case PillType.UPLOADING:
    case PillType.UPLOADED:
      src = `${process.env.PUBLIC_URL}/assets/upload.png`;
      break;
    case PillType.POLICY:
      src = `${FileText}`;
      break;
    case PillType.PROPOSAL:
      src = `${ProposalsIcon}`;
      break;
    case PillType.COMPARISON:
      src = `${Files}`;
      break;
    case "Document":
      src = `${File}`;
      break;
    default:
      src = "";
      break;
  }

  return src && <IconContainer src={src} alt={type} />;
};

export function mapToPillType(value: MappableType): PillType {
  switch (value) {
    case DocumentType.COMPARISON:
      return PillType.COMPARISON;

    case DocumentType.POLICY:
      return PillType.POLICY;

    case DocumentType.PROPOSAL:
      return PillType.PROPOSAL;

    default:
      throw new Error(`Unmapped value: ${value}`);
  }
}

export function mapToMappableType(value: PillType): MappableType {
  switch (value) {
    case PillType.COMPARISON:
      return DocumentType.COMPARISON;

    case PillType.POLICY:
      return DocumentType.POLICY;

    case PillType.PROPOSAL:
      return DocumentType.PROPOSAL;

    default:
      throw new Error(`Unmapped value: ${value}`);
  }
}
