import React, { useRef } from "react";
import { FileUploadButtonProps } from "./types";
import { HiddenInput } from "./styles";
import Button from "../Button";
import Upload from "../../assets/icons/Upload.svg";

const FileUploadButton: React.FC<FileUploadButtonProps> = ({
  handleFileChange,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <Button iconLeft={Upload} text="Upload" handleClick={handleUpload} />
      <HiddenInput
        type="file"
        ref={fileInputRef}
        multiple
        onChange={(e) => handleFileChange(e)}
      />
    </>
  );
};

export default FileUploadButton;
