import { DocumentType } from "../Document";
import { Header } from "../DocumentWizardTable/types";

export interface PolicyComparisonWizardProps {
    handleCancel: () => void;
    handleFileChange: (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent) => void;
    rows: DocumentType[];
    onSubmit: (initial: DocumentType[], displayName: string, createdFor?: string, upsellPolicy?: string) => Promise<void>;
    alreadySelected?: string[];
    successfulUploads?: string[];
    agencyId?: string;
    retrieveDocuments?: () => void;
}

export enum Pages {
    SELECT_FIRST_POLICY = "Select First Policy",
    SELECT_NAME = "Select Name",
    SELECT_LINES = "Select Lines",
    UPSELL_POLICY = "Upsell Policy"
}

export interface SelectFirstPolicyProps {
    rows: DocumentType[];
    headers: Header[];
    handleSelect: (rows: DocumentType[]) => void;
    handleFileChange: (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent) => void;
    handleContinue: () => void;
    handleCancel: () => void;
    progressBlocked?: boolean;
    selected: DocumentType[];
    handleRemoveFromSelected: (row: DocumentType) => void;
    successfulUploads?: string[];
}

export interface UpsellPolicyProps {
    rows: DocumentType[];
    headers: Header[];
    handleSelect: (s: string) => void;
    handleContinue: () => void;
    handleCancel: () => void;
    progressBlocked?: boolean;
    selected: string;
    handleRemoveFromSelected: (row: DocumentType) => void;
}

export interface SelectNameProps {
    defaultName: string;
    onComplete: (s: string, s2?: string) => void;
    handleCancel: () => void;
    documents: DocumentType[];
    onBack: () => void;
    proposal?: boolean;
    agencyId?: string;
}

export interface SelectSecondPolicyProps extends SelectFirstPolicyProps {
    selectedSecondary: DocumentType[];
    handleRemoveFromSelectedSecondary: (row: DocumentType) => void;
}

export enum Lines {
    CYBER = "Cyber",
    D_O = "D&O",
    PROF_LIABILITY = "Professional Liability",
    COMMERCIAL_PROP = "Commercial Property",
    PERSONAL_PROP = "Personal Property",
    COMMERCIAL_AUTO = "Commercial Auto",
    PERSONAL_AUTO = "Personal Auto",
    GENERAL_LIABILITY = "General Liability",
    LIQUOR_LIABILITY = "Liquor Liability",
    WORKERS_COMP = "Workers Comp",
    BOP = "BOP",
    GARAGE = "Garage",
    EPL = "EPL",
    CRIME = "Crime",
    POLLUTION_LIABILITY = "Pollution Liability",
    LOGGING = "Logging",
    BUILDERS_RISK = "Builder's Risk",
    TERRORISM = "Terrorism",
    INLAND_MARINE = "Inland Marine",
    UMBRELLA = "Umbrella",
    FARM = "Farm",
    FIDUCIARY_LIABILITY = "Fiduciary Liability",
    EQUIPMENT_BREAKDOWN = "Equipment Breakdown"
}

export interface SelectComparisonProps {
    onComplete: (s: string[]) => void;
    handleCancel: () => void;
    selected: string[];
    setSelected: (s: string[]) => void;
    onBack?: () => void;
    policyName?: string;
    policyId?: string;
    singlePolicy?: boolean;
    proposal?: boolean;
    loading?: boolean;
    documents: DocumentType[];
    handleFileChange: (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent, docType?: "policy" | "endorsement", associatedDocumentId?: string) => void;
    retrieveDocuments?: () => void;
}