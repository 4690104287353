import React, { useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  MDXEditor,
  jsxPlugin,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  linkPlugin,
  toolbarPlugin,
  tablePlugin,
  markdownShortcutPlugin,
  frontmatterPlugin,
  thematicBreakPlugin,
  KitchenSinkToolbar,
  directivesPlugin,
} from "@mdxeditor/editor";

import { EditableTextProps } from "./types";
import Button from "../Button";
import Check from "../../assets/icons/Check.svg";
import XClose from "../../assets/icons/x-close.svg";
import { ButtonWrapper, EditableTextContainer, TextWrapper } from "./styles";
import TextInput from "../TextInput";
import PenLine from "../../assets/icons/PenLine.svg";

const EditableText: React.FC<EditableTextProps> = ({
  defaultValue,
  onChange,
  onSubmitChange,
  onClick,
  markdown,
  notEditable,
  marginLeft,
  $alwaysBorder,
  $grow,
  $noPadding,
  placeholder,
}) => {
  const [valueBeforeEdit, setValueBeforeEdit] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [internalValue, setInternalValue] = useState<string>(
    defaultValue || ""
  );

  const handleChange = (newValue: string) => {
    if (newValue.length === 0) {
      setIsError(true);
    } else {
      setIsError(false);
    }

    if (newValue.length > 0) {
      setInternalValue(newValue);
      onChange?.(newValue);
    }
  };

  const handleCommenceEdit = () => {
    setValueBeforeEdit(internalValue);
    setIsEditing(true);
  };

  const handleSubmitChange = () => {
    if (isError) {
      return;
    }
    setIsEditing(false);
    onSubmitChange(internalValue);
  };

  const handleDeleteChange = () => {
    setInternalValue(valueBeforeEdit);
    setIsEditing(false);
  };

  return (
    <EditableTextContainer
      isEditing={isEditing}
      isError={isError}
      marginLeft={marginLeft}
      $alwaysBorder={$alwaysBorder}
      $grow={$grow}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <TextWrapper>
        {!markdown && (
          <TextInput
            initialValue={internalValue}
            $borderless
            hideClear
            disabled={!isEditing}
            $grow={!$noPadding}
            $noPadding={$noPadding}
            onChangeCallback={(e) => handleChange(e)}
            onClick={onClick}
            placeholder={placeholder || "Please enter a value"}
          />
        )}
        {markdown && (
          <>
            {!isEditing && (
              <ReactMarkdown
                components={{
                  h1: ({ node, ...props }) => (
                    <h1
                      style={{ fontSize: "2em", fontWeight: "bold" }}
                      {...props}
                    />
                  ),
                  h2: ({ node, ...props }) => (
                    <h2
                      style={{ fontSize: "1.7em", fontWeight: "bold" }}
                      {...props}
                    />
                  ),
                  h3: ({ node, ...props }) => (
                    <h2
                      style={{ fontSize: "1.4em", fontWeight: "bold" }}
                      {...props}
                    />
                  ),
                  h4: ({ node, ...props }) => (
                    <h2
                      style={{ fontSize: "1.2em", fontWeight: "bold" }}
                      {...props}
                    />
                  ),
                  strong: ({ node, ...props }) => (
                    <strong style={{ fontWeight: "bold" }} {...props} />
                  ),
                  table: ({ node, ...props }) => (
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        marginBottom: "1em",
                      }}
                      {...props}
                    />
                  ),
                  thead: ({ node, ...props }) => <thead {...props} />,
                  th: ({ node, ...props }) => (
                    <th
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        padding: "8px",
                        borderBottom: "2px solid #ddd",
                      }}
                      {...props}
                    />
                  ),
                  td: ({ node, ...props }) => (
                    <td
                      style={{ padding: "8px", borderBottom: "1px solid #ddd" }}
                      {...props}
                    />
                  ),
                }}
                remarkPlugins={[remarkGfm]}
              >
                {internalValue}
              </ReactMarkdown>
            )}
            {isEditing && (
              <MDXEditor
                markdown={internalValue}
                plugins={[
                  headingsPlugin(),
                  listsPlugin(),
                  quotePlugin(),
                  linkPlugin(),
                  tablePlugin(),
                  directivesPlugin(),
                  jsxPlugin(),
                  thematicBreakPlugin(),
                  markdownShortcutPlugin(),
                  frontmatterPlugin(),
                ]}
                onChange={(e) => handleChange(e)}
              />
            )}
          </>
        )}
      </TextWrapper>
      {!isEditing && !notEditable && (
        <Button
          iconLeft={PenLine}
          handleClick={handleCommenceEdit}
          $borderless
          $background="transparent"
        />
      )}
      {isEditing && (
        <ButtonWrapper>
          <Button
            iconLeft={XClose}
            handleClick={handleDeleteChange}
            $borderless
            $background="transparent"
          />
          <Button
            iconLeft={Check}
            handleClick={handleSubmitChange}
            $borderless
            disabled={isError}
            $background="transparent"
          />
        </ButtonWrapper>
      )}
    </EditableTextContainer>
  );
};

export default EditableText;
