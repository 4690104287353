import styled from "styled-components";
import COLORS from "../../designSystem/colors";
import SIZING from "../../designSystem/sizing";

export const BlurredBackground = styled.div`
  position: absolute;
  top: -12px;
  left: -12px;
  border-radius: 8px;
  width: calc(100% + 24px);
  height: calc(100% + 24px);
  background: rgba(0, 0, 0, 0.1); /* Semi-transparent overlay */
  backdrop-filter: blur(8px); /* Blurred effect */
  z-index: 1000; /* Ensure it stays on top */
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OverlayText = styled.div`
  color: white;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  z-index: 1100; /* Ensure text stays above the blurred background */
`;

export const CreateProposalContainer = styled.div`
    position: relative;
    width: 856px;
    z-index: 999;
`;

export const TableContainer = styled.div`
    flex: 1;
    border: 1px solid ${COLORS.UI.BORDER.STANDARD};
    border-radius: ${SIZING.RADIUS.MEDIUM};
`;

export const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
`;
    

export const THead = styled.thead`
    border-bottom: 1px solid ${COLORS.UI.BORDER.STANDARD};
`;

export const Tr = styled.tr`
    border-bottom: 1px solid ${COLORS.UI.BORDER.STANDARD};
`;

export const TrWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    div:first-child {
        margin-right: ${SIZING.SPACING.STANDARD}
    }
`;

export const Th = styled.th`
    padding: ${SIZING.SPACING.LARGE};
    text-align: left;
    font-weight: 500;
`;

export const TBody = styled.tbody`
    background-color: ${COLORS.UI.BACKGROUND.STANDARD};
`;

export const Td = styled.td`
    padding: ${SIZING.SPACING.LARGE};
`;

export const ThDocDescription = styled(Th)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const SearchWrapper = styled.div`
    margin: ${SIZING.SPACING.LARGE} 0 ${SIZING.SPACING.STANDARD} 0;
`;

export const PaginationWrapper = styled.div`
    display: flex;
    padding: ${SIZING.SPACING.STANDARD};
    align-items: center;
    justify-content: space-between;
`;

export const PaginationButtonWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: ${SIZING.SPACING.LARGE};
    padding-top: ${SIZING.SPACING.STANDARD};
    
    button:last-child {
        margin-left: ${SIZING.SPACING.STANDARD};
    }
`;

export const SelectedDocumentsWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    & > button {
        margin-top: ${SIZING.SPACING.LARGE};
        margin-right: ${SIZING.SPACING.SMALL}
    }
`;