// Rem values calculated from base font size of 14px

export default {
  SPACING: {
    SMALLEST: "0.3rem",
    SMALL: "0.571rem", //8px
    STANDARD: "0.857rem", //12px
    LARGE: "1.143rem", // 16px
    DOUBLE_LARGE: "2.286rem",
    QUAD_LARGE: "4.372rem",
  },
  RADIUS: {
    SMALL: "4px",
    MEDIUM: "0.428571rem", //6px
  },
  ICON: {
    HEIGHT: {
      STANDARD: "16px",
    },
  },
  TEXT: {
    H1: "35px",
    H2_LARGE: "24px",
    H2: "20px",
    LARGE: "16px",
    MEDIUM: "14px",
    SMALL: "12px",
  },
};
