import styled from "styled-components";
import sizing from "../../designSystem/sizing";
import colors from "../../designSystem/colors";

export const FileCardContainer = styled.div`
  border: 1px solid ${colors.UI.BORDER.STANDARD};
  border-radius: ${sizing.RADIUS.MEDIUM};
  padding: ${sizing.SPACING.STANDARD};
  width: 264px;
  min-width: 264px;
  max-width: 264px;
  min-height: 180px;
  max-height: 180px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${colors.UI.BACKGROUND.STANDARD};

  &:hover {
    background-color: ${colors.UI.BACKGROUND.SELECTED};
  }
`;

export const IndicatorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: top;
`;

export const SpinnerWrapper = styled.div`
  & > :last-child {
    transform: scale(0.33);
    position: absolute;
    right: -16px;
    top: -16px;
  }
`;

export const ColoredTextWrapper = styled.div``;

export const Header = styled.h2`
  font-size: ${sizing.TEXT.LARGE};
  font-weight: 500;
  margin: 0 0 ${sizing.SPACING.SMALL};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const PillContainer = styled.div`
  display: flex;
  max-width: 100%;
  overflow: hidden;

  & > :first-child {
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;
  }

  & > * {
    margin-right: ${sizing.SPACING.STANDARD};
    justify-content: space-between;
  }

  & :last-child {
    margin-right: 0 !important;
  }
`;

// export const HiddenPillContainer = styled(PillContainer)`
//   //   visibility: hidden;
//   position: absolute;
// `;
