import styled from "styled-components";
import colors from "../../designSystem/colors";
import sizing from "../../designSystem/sizing";
import { LoadingStepProps } from "./types";


export const ComparisonLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  margin: ${sizing.SPACING.STANDARD} 0;
  width: 100%;
  margin-top: 30px;
`;

export const LoaderSubText = styled.div`
  color: #7A7A7A;
  font-size: 16px;
`

export const LoadingStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
`

export const LoadingStep = styled.div<LoadingStepProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  /*width: 293px;*/
  width: 393px;
  height: 30px;
  border-radius: 8px;
  gap: 12px;
  border: 1px solid #CFCFCF59;
  padding: 12px 16px 12px 16px;
  background-color: ${({ loaded }) => loaded ? "#E1FDE6" : "white" };
`