import React, { useState, useRef, useEffect, ReactNode } from "react";
import { BrowserRouter, Routes, Route, useNavigate, useParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Socket, io } from "socket.io-client";
import Intercom from '@intercom/messenger-js-sdk';
import 'intro.js/introjs.css';

import LoadingSpinner from "../components/base/Spinner";

import "./LandingPage.css";
import { retrieveReportRuns, retrieveExistingDocuments, retrieveClients, retrieveProposals, retrieveAgency, retrieveDownIndicators, generateProposal, uploadDocument, updateDocument, s3Presign, comparePolicyCoverage, analyzePolicy, retrieveUser, deleteDocument } from "../requests";
import { DocumentType, ReportRunType } from "../components/Document";
import PDFModal, { ResultWithReference } from "../components/PDFModal";
import SettingsModal from "../components/SettingsModal";
import CreateClientModal from "../components/CreateClientModal";
import Text from "../components/base/Text";
import Space from "../components/base/Space";
import { handleDownload, isAutoVerified } from "../utils";

import { DocumentObject, Pages, PresignData } from "./types";
import Dashboard from "../pages/Dashboard";
import { useClients } from "../hooks/useClients";
import Toast from "../components/Toast";
import { ToastText } from "../components/DocumentTable/styles";
import Button from "../components/Button";
import ToastDocumentAction from "../components/ToastDocumentAction";
import ComparisonPage from "../pages/Comparison";

import Login from "../Login";
import CreateAccount from "../CreateAccount";
import RequestResetPassword from "../RequestResetPassword";
import ResetPassword from "../ResetPassword";
import { PillType } from "../components/Pill/types";

export type ClientType = {
  id: string;
  name: string;
}

export type UserType = {
  id: string;
  username: string;
  email: string;
  created_at?: string;
  homepage_wt_completed?: boolean;
  analysis_wt_completed?: boolean;
  comparison_wt_completed?: boolean;
}

const LandingPage = React.memo(({}) => {

  const [user, setUser] = useState<UserType | null>(null);
  const [documents, setDocuments] = useState<DocumentType[]>([]);
  const [reports, setReports] = useState<DocumentType[]>([]);
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [socket, setSocket] = useState<Socket | null>(null);
  const [sid, setSid] = useState<string>("");
  const [page, setPage] = useState<Pages>(Pages.DOCUMENTS);
  const [clientId, setClientId] = useState<string>(() => {
    return localStorage.getItem('selectedClientId') || "default_client";
  });
  const [prevClientId, setPrevClientId] = useState<string>("");
  const [pdfModalOpen, setPdfModalOpen] = useState<boolean>(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState<boolean>(false);
  const [comparisonModalOpen, setComparisonModalOpen] = useState<boolean>(false);
  const [createClientModalOpen, setCreateClientModalOpen] = useState<boolean>(false);
  const [addLinesModalOpen, setAddLinesModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ReactNode | null>(null);
  const [successMessage, setSuccessMessage] = useState<ReactNode | null>(null);
  const [storageValue, setStorageValue] = useState(localStorage.getItem('authToken') || '');
  const [agencyId, setAgencyId] = useState<string>("");

  const [clients, setClients] = useState<ClientType[]>([]);

  const [document, setDocument] = useState<DocumentType | null>(null);
  const [secondDocument, setSecondDocument] = useState<DocumentType | null>(null);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);

  const [isDown, setIsDown] = useState<boolean>(false);
  const [isDownReason, setIsDownReason] = useState<string[]>([]);

  const [isPortrait, setIsPortrait] = useState<boolean>(false);
  const navigate = useNavigate();

  const [documentView, setDocumentView] = useState<DocumentType>();

  const [createProposalModalOpen, setCreateProposalModalOpen] = useState<boolean>(false);
  const [comparePolicyModalOpen, setComparePolicyModalOpen] = useState<boolean>(false);
  const { data: clientList = [], isLoading: clientsIsLoading, isError: clientsIsError, error: clientsError, refetch: refetchClients } = useClients(cookies['user-id']);

  const [showUploadToast, setShowUploadToast] = useState<boolean>(false);
  const [showDownloadToast, setShowDownloadToast] = useState<boolean>(false);
  const [showDeleteToast, setShowDeleteToast] = useState<boolean>(false);
  const [showProposalToast, setShowProposalToast] = useState<boolean>(false);
  const [showComparisonToast, setShowComparisonToast] = useState<boolean>(false);
  const [showSpreadsheetToast, setShowSpreadsheetToast] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [downloading, setDownloading] = useState<boolean>(false);
  const [spreadsheetDownloading, setSpreadsheetDownloading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [generatingProposal, setGeneratingProposal] = useState<boolean>(false);
  const [generatingComparison, setGeneratingComparison] = useState<boolean>(false);

  const [successfulUploads, setSuccessfulUploads] = useState<string[]>([]);
  const [errorUploads, setErrorUploads] = useState<string[]>([]);

  const [showSummary, setShowSummary] = useState<boolean>(true);
  const [filter, setFilter] = useState<PillType | undefined>(PillType.POLICY);

  Intercom({
    app_id: 'zse41vsn',
    user_id: user?.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: user?.username, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: user?.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    created_at: user?.created_at ? new Date(user.created_at).getMilliseconds() : undefined, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
  });

  useEffect(() => {
    if (showUploadToast && !uploading) {
      const timer = setTimeout(() => {
        setShowUploadToast(false);
        setError(null);
      }, 5000); // 5 seconds
  
      return () => clearTimeout(timer);
    } 
    
    if (showDownloadToast && !downloading) {
      const timer = setTimeout(() => {
        setShowDownloadToast(false);
        setError(null);
      }, 5000); // 5 seconds
  
      return () => clearTimeout(timer);
    } 
    
    if (showDeleteToast) {
      const timer = setTimeout(() => {
        setShowDeleteToast(false);
        setError(null);
      }, 5000); // 5 seconds
  
      return () => clearTimeout(timer);
    } 
    
    if (showProposalToast) {
      const timer = setTimeout(() => {
        setShowProposalToast(false);
        setError(null);
      }, 5000); // 5 seconds
  
      return () => clearTimeout(timer);
    } 
    
    if (showComparisonToast) {
      const timer = setTimeout(() => {
        setShowComparisonToast(false);
        setError(null);
      }, 5000); // 5 seconds
  
      return () => clearTimeout(timer);
    } 
    
    if (showSpreadsheetToast) {
      const timer = setTimeout(() => {
        setShowSpreadsheetToast(false);
        setError(null);
      }, 5000); // 5 seconds
  
      return () => clearTimeout(timer);
    }
  }, [showUploadToast, uploading, showDownloadToast, downloading, showDeleteToast, showComparisonToast, showProposalToast, showSpreadsheetToast]);

  useEffect(() => {
    console.log("ABOUT TO CREATE NEW SOCKET");
    const newSocket = io(process.env.REACT_APP_BE_URL || "", {
      query: {
        'auth-token': cookies['user-id'],
      },
      transports: ['websocket']
    });

    setSocket(newSocket);

    console.log("CREATED");

    return () => {
      newSocket.close();
    };
  }, [cookies['user-id']]);

  useEffect(() => {
    socket?.on("refresh", (data) => {
      console.log("RECEIVED REFRESH");
      retrieveDocuments(true);
      //retrieveDownIndicator();
    });

    return () => {
      socket?.off("refresh");
    };
  }, [socket]);

  useEffect(() => {
    const checkOrientation = () => {
      setIsPortrait(window.innerWidth < (window.innerHeight + 500));
    };
    checkOrientation();
    window.addEventListener('resize', checkOrientation);
    return () => window.removeEventListener('resize', checkOrientation);
  }, []);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, {
        position: "top-center",
        closeOnClick: true,
        autoClose: false,
        pauseOnHover: true,
        draggable: true,
      });
    }

  }, [errorMessage])

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage, {
        position: "top-center",
        closeOnClick: true,
        autoClose: false,
        pauseOnHover: true,
        draggable: true,
      });
    }

  }, [successMessage])

  useEffect(() => {
    const handleStorageChange = (event) => {
        if (event.key === 'authToken') {
            setStorageValue(event.newValue);
        }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
        window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // Effect to update the cookie whenever the state changes
  useEffect(() => {
      if (storageValue && storageValue !== "") {
        setCookie('user-id', storageValue, { maxAge: 60 * 60 * 24 * 14 })
      }
  }, [storageValue]);

  useEffect(() => {
    if (!cookies['user-id']) {
      navigate("/login");
      setSettingsModalOpen(false);
      setAddLinesModalOpen(false);
      setCreateClientModalOpen(false);
      localStorage.setItem("selectedClientId", "default_client");
    }
  }, [cookies['user-id']])

  const retrieveDocuments = async (fromSocketRefresh?: boolean) => {
    if (clientId != prevClientId && !fromSocketRefresh) {
      setLoadingDocuments(true);
    }

    const resp = await retrieveExistingDocuments(cookies['user-id'], localStorage.getItem("selectedClientId") || "default_client");
    const resp2 = await retrieveReportRuns(cookies['user-id'], localStorage.getItem("selectedClientId") || "default_client");
    const resp3 = await retrieveProposals(cookies['user-id'], localStorage.getItem("selectedClientId") || "default_client");

    const policies = resp.body["documents"].map(d => {
      return {
        id: d["document_id"],
        category: d["category"],
        instanceId: d["document_instance_id"],
        associatedDocumentId: d["associated_document_id"],
        lines: d["coverage_lines"],
        name: d["display_name"] ?? d["name"],
        link: d["s3_location"] ?? d["web_link"],
        status: d["status"],
        percentComplete: d["percent_complete"],
        clientId: d["client_id"],
        createdAt: d["created_at"],
        percentVerified: isAutoVerified(d["created_at"]) ? 100 : (d["percent_verified"] ?? 0),
        websiteReady: d["website_ready"]
      }
    });

    const extraReports: any[] = [];
    const origReports = resp2.body["report_runs"].map(d => {
      const reportType = d["instance_ids"].length > 1 ? "comparison" : "analysis"
      let newName = "";
      const name = policies.reduce(
        (prev, curr) => (d["instance_ids"].includes(curr.instanceId) ? curr.name + " / " : "") + prev, ""
      )
      if (reportType == "comparison") {
        newName = name.slice(0, name.length - 2) + " - Comparison";
      } else {
        newName = name.slice(0, name.length - 2) + " - Report";
      }

      var vehicleScheduleName = name.slice(0, name.length - 2) + " - Vehicle Schedule";
      
      if (d["excel_path"]) {
        extraReports.push({
          id: d["document_id"],
          category: reportType,
          instanceId: d["run_id"],
          instanceIds: d["instance_ids"],
          name: d["display_name"] ?? newName.slice(0, 80),
          link: d["excel_path"],
          pdfSummaryLink: d["pdf_path"],
          status: d["status"],
          percentComplete: 100,
          reportInitiated: d["generation_time"],
          createdAt: d["generation_time"],
          websiteReady: d["website_ready"],
          differencesCount: d["difference_count"]
        });
      } else if (d["vehicle_excel"]) {
        extraReports.push({
          id: d["document_id"],
          category: reportType,
          instanceId: d["run_id"],
          instanceIds: d["instance_ids"],
          name: d["display_name"] ?? vehicleScheduleName,
          link: d["vehicle_excel"],
          pdfSummaryLink: d["pdf_path"],
          status: d["status"],
          percentComplete: 100,
          reportInitiated: d["generation_time"],
          websiteReady: d["website_ready"],
          differencesCount: d["difference_count"]
        });
      } else if (d["status"] == "analyzing" || d["status"] == "generating" || d["status"] == "failed" || d["website_ready"]) {
        extraReports.push({
          id: d["document_id"],
          category: reportType,
          instanceId: d["run_id"],
          instanceIds: d["instance_ids"],
          name: d["display_name"] ?? newName,
          link: d["report_location"],
          pdfSummaryLink: d["pdf_path"],
          status: d["status"],
          percentComplete: 100,
          reportInitiated: d["generation_time"],
          createdAt: d["generation_time"],
          websiteReady: d["website_ready"],
          differencesCount: d["difference_count"]
        })
      }
      
      return {
        id: d["document_id"],
        category: reportType,
        instanceId: d["run_id"],
        instanceIds: d["instance_ids"],
        name: d["display_name"] ?? newName,
        link: d["report_location"],
        pdfSummaryLink: d["pdf_path"],
        status: d["status"],
        percentComplete: 100,
        reportInitiated: d["generation_time"],
        createdAt: d["generation_time"],
        websiteReady: d["website_ready"],
        differencesCount: d["differences_count"]
      }
    });

    const singleReports = [...extraReports, ...origReports].filter(r => r.instanceIds && r.instanceIds.length == 1)
    const comparisonReports = [...extraReports].filter(r => r.instanceIds && r.instanceIds.length > 1)

    const proposals = resp3.body["proposals"].map(d => {
      let newName = "";
      const name = policies.reduce(
        (prev, curr) => (d["instance_ids"].includes(curr.instanceId) ? curr.name + " / " : "") + prev, ""
      )
      newName = name.slice(0, name.length - 2) + " - Proposal";
      
      return {
        id: d["document_id"],
        category: "proposal",
        instanceId: d["proposal_id"],
        instanceIds: d["instance_ids"],
        name: d["display_name"] ?? newName,
        link: d["file_key"],
        status: d["status"],
        percentComplete: 100,
        reportInitiated: d["generation_time"],
        createdAt: d["generation_time"],
        websiteReady: d["website_ready"]
      }
    });

    setDocuments([...policies, ...comparisonReports, ...proposals]);
    setReports(singleReports);

    setPrevClientId(clientId);
    setClientId(localStorage.getItem("selectedClientId") || "default_client");
    setLoadingDocuments(false);
  }

  const retrieveAgencyId = async () => {
    const resp = await retrieveAgency(cookies['user-id']);
    setAgencyId(resp.body["agency_id"]);
  }

  const retrieveDownIndicator = async () => {
    const resp = await retrieveDownIndicators(cookies['user-id']);
    setIsDown(resp.body["is_down"] ?? false);
    setIsDownReason(resp.body["is_down_reason"] ?? "");
  }

  const retrieveUserInfo = async () => {
    const resp = await retrieveUser(cookies['user-id']);
    setUser({ 
      "id": resp.body["user_id"],
      "email": resp.body["email"],
      "username": resp.body["username"],
      "created_at": resp.body["created_at"] ?? undefined,
      "homepage_wt_completed": resp.body["homepage_wt_completed"] ?? false,
      "analysis_wt_completed": resp.body["analysis_wt_completed"] ?? false,
      "comparison_wt_completed" : resp.body["comparison_wt_completed"] ?? false
    });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        documents.find(d => d.status === "analyzing" || d.status === "analyzing_policy") ||
        reports.find(d => d.status === "analyzing" || d.status === "analyzing_policy")
      ) {
        retrieveDocuments();
      }
    }, 60000);
  
    return () => clearInterval(interval);
  }, [documents, reports]);

  useEffect(() => {
    if (cookies['user-id'])  {
      retrieveUserInfo();
      retrieveAgencyId();
      retrieveDocuments();
      refetchClients();
      retrieveDownIndicator();
      //navigate("/");
    }
  }, [cookies["user-id"], clientId])

  useEffect(() => {
    if (isDown && isDownReason.length > 0) {
      setErrorMessage(null);

      setErrorMessage(
        <>
          <Text size={18} weight={600}>
            {isDownReason.length > 1
              ? `${isDownReason[0]} and ${isDownReason[1]}, our AI model providers, are currently down.`
              : `${isDownReason[0]}, one of our AI model providers, is currently down.`}
          </Text>
          <Space px={20} />
          <Text size={16}>
            We have contacted their team{isDownReason.length > 1 ? "s" : ""} and requested the issue be fixed immediately.
          </Text>
          <Space px={20} />
          <Text size={16}>
            For the moment, new policy analyses and comparisons will not run.
          </Text>
        </>
      )
    }
  }, [isDown, isDownReason])

  const handleSelectDocumentView = (document: DocumentType) => {
    setDocumentView(document);
    switch(document.category) {
      case "comparison":
        navigate(`/comparison/${document.instanceId}`);
        break;
      case "policy":
      default:
        navigate(`/document/${document.instanceId}`);
        setPdfModalOpen(true);
        break;
    }
    window.scrollTo(0, 0);
  }

  const handleCreateProposal = async (proposals: DocumentType[], displayName: string) => {
    // TODO // need to understand auto refresh before I use the service layer
    // await documentService.createProposal(cookies['user-id'], proposals.map(d => d?.instanceId ?? "")
    setGeneratingProposal(true);
    setShowProposalToast(true);
    setCreateProposalModalOpen(false);
    await generateProposal(cookies['user-id'], proposals.map(d => d?.instanceId ?? ""), displayName);
    retrieveDocuments();
    setGeneratingProposal(false);
    navigate("/");
  }

  const handleCreateComparison = async (initial: DocumentType[], displayName: string, createdFor?: string, upsellPolicy?: string) => {
    //TODO What should this endpoint be? Compare Policies doesn't seem to match up
    setGeneratingComparison(true);
    setShowComparisonToast(true);
    setComparePolicyModalOpen(false);
    console.log(createdFor);
    console.log(upsellPolicy);
    const resp = await comparePolicyCoverage(cookies['user-id'], initial.map(i => i.instanceId ?? ""), true, displayName, createdFor, upsellPolicy);
    retrieveDocuments();
    await new Promise(r => setTimeout(r, 1500));
    setGeneratingComparison(false);
    navigate(`/comparison/${resp.body["run_id"]}`);
  }


  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement> | React.DragEvent,
    docType?: "policy" | "endorsement",
    associatedDocumentId?: string
  ) => {
    setUploading(true);
    setShowUploadToast(true);
    let files: FileList | null = null;
  
    if ("dataTransfer" in event) {
      files = event.dataTransfer?.files ?? null;
    } else {
      files = event.target.files;
    }
  
    if (files && files.length > 0) {
      const uploadPromises = Array.from(files).map((file) => 
        handleFileUpload(file, docType ? docType : "policy", associatedDocumentId ? associatedDocumentId : undefined)
      );
  
      await Promise.all(uploadPromises);
    }

    retrieveDocuments();
    setUploading(false);
  };

  const handleFileUpload = async (file: File, category: 'policy' | 'endorsement', associatedDocumentId?: string): Promise<string | void> => {
    let newDocumentInstanceId = "";
    let errorThrown = false;
    try {
      const maxSize = 25 * 1024 * 1024;
      if (file.size > maxSize) {
        setErrorMessage('Warning: File size exceeds 25 MB. Upload could take several minutes.');
      } else if (file.size <= 0) {
        throw new Error('File is empty (size is 0 bytes)');
      }
      const fileKey = `${Date.now()}_${file.name}`;
      const resp = await uploadDocument(cookies["user-id"] || '', fileKey, category, clientId, associatedDocumentId, file.name);
      newDocumentInstanceId = resp.body['document_instance_id'];
      await retrieveDocuments();

      // Get the pre-signed URL from the Flask backend
      const presignResponse = await s3Presign(cookies["user-id"] || '', fileKey);
      const presignData: PresignData = presignResponse.body["url"];

      // Prepare the form data for posting the PDF file
      const formData = new FormData();
      Object.entries(presignData.fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append('file', file);

      // Post the file to the S3 bucket using the pre-signed URL
      const uploadResponse = await fetch(presignData.url, {
        method: 'POST',
        body: formData
      });

      if (uploadResponse.ok) {
        await updateDocument(cookies["user-id"] || '', resp.body['document_instance_id'], "uploaded", "", fileKey);
        
        setSuccessfulUploads(prev => [...prev, newDocumentInstanceId]);
      } else {
        setErrorUploads(prev => [...prev, newDocumentInstanceId]);
        throw new Error('Upload failed—try again in a minute');
      }
    } catch (err) {
      // if (newDocumentInstanceId !=  "") {
      //   await deleteDocument(cookies["user-id"] || '', newDocumentInstanceId, false, false);
      // }
      // setError((err as Error).message);
      // errorThrown = true;
    }
    if (!comparePolicyModalOpen && !createProposalModalOpen && !errorThrown) setAddLinesModalOpen(true);
    //return newDocumentInstanceId;
  }

  const onDownload = async (document: DocumentType | undefined) => {
    setShowDownloadToast(false);
    setDownloading(true);
    setShowDownloadToast(true);
    await handleDownload(document, cookies['user-id'], window);
    setDownloading(false);
  }

  const onDownloadComparison = async (doc: DocumentType | undefined) => {
    if (doc) {
      setShowSpreadsheetToast(true);
      setSpreadsheetDownloading(true);
      await handleDownload(doc, cookies['user-id'], window);
      setSpreadsheetDownloading(false);
    }
  }

  const onDownloadQuoteSummary = async (doc: DocumentType | undefined) => {
    if (doc) {
      setDownloading(true);
      setShowDownloadToast(true);
      await handleDownload(doc, cookies['user-id'], window, true);
      setDownloading(false);
    }
  }

  return (
    <>
      <Routes>
        <Route path="/login" element={ <Login />} />
        <Route path="/create-account" element={ <CreateAccount />} />
        <Route path="/request-reset-password" element={ <RequestResetPassword />} />
        <Route path="/reset-password/:reset_id" element={ <ResetPassword />} />
        {/* Dashboard Route */}
        <Route
          path="/"
          element={
            <Dashboard
              docList={documents}
              reports={reports}
              retrieveDocuments={retrieveDocuments}
              clientId={clientId}
              clients={clientList}
              setClientId={setClientId}
              setCreateClientModalOpen={setCreateClientModalOpen}
              setSettingsModalOpen={setSettingsModalOpen}
              onDocumentView={handleSelectDocumentView}
              onHome={() => navigate('/')}
              createProposalModalOpen={createProposalModalOpen}
              setCreateProposalModalOpen={setCreateProposalModalOpen}
              comparePolicyModalOpen={comparePolicyModalOpen}
              setComparePolicyModalOpen={setComparePolicyModalOpen}
              addLinesModalOpen={addLinesModalOpen}
              setAddLinesModalOpen={setAddLinesModalOpen}
              isLoading={loadingDocuments}
              onCreateProposal={handleCreateProposal}
              onCreateComparison={handleCreateComparison}
              cookie={cookies['user-id']}
              handleFileChange={handleFileChange}
              agencyId={agencyId}
              showSummary={showSummary}
              setShowSummary={setShowSummary}
              successfulUploads={successfulUploads}
              setSuccessfulUploads={setSuccessfulUploads}
              filter={filter}
              setFilter={setFilter}
            />
          }
        />

        {/* Single Document View Route */}
        <Route
          path="/document/:documentId"
          element={
            <PDFModal
              setModalOpen={setPdfModalOpen}
              modalOpen={true}
              documents={documents}
              reports={reports}
              onDocumentView={handleSelectDocumentView}
              retrieveDocuments={retrieveDocuments}
              setSuccessMessage={setSuccessMessage}
              setErrorMessage={setErrorMessage}
              setComparisonModalOpen={setComparisonModalOpen}
              clientId={clientId} 
              setClientId={setClientId}
              setCreateClientModalOpen={setCreateClientModalOpen}
              setSettingsModalOpen={setSettingsModalOpen}
              onHome={() => navigate('/')}
              clients={clientList}
              createProposalModalOpen={createProposalModalOpen}
              setCreateProposalModalOpen={setCreateProposalModalOpen}
              comparePolicyModalOpen={comparePolicyModalOpen}
              setComparePolicyModalOpen={setComparePolicyModalOpen}
              onCreateProposal={handleCreateProposal}
              onCreateComparison={handleCreateComparison}
              handleFileChange={handleFileChange}
              onDownload={onDownload}
              setDeleting={setDeleting}
              setShowDeleteToast={setShowDeleteToast}
              successfulUploads={successfulUploads}
              setSuccessfulUploads={setSuccessfulUploads}
              agencyId={agencyId}
            />
          }
        />

        {/* Comparison View Route */}
        <Route
          path="/comparison/:comparisonId"
          element={
            <ComparisonPage
              user={user}
              setModalOpen={setComparisonModalOpen}
              modalOpen={true}
              retrieveDocuments={retrieveDocuments}
              setSuccessMessage={setSuccessMessage}
              setErrorMessage={setErrorMessage}
              setSecondDocument={setSecondDocument}
              clientId={clientId}
              clients={clientList}
              setClientId={setClientId}
              setCreateClientModalOpen={setCreateClientModalOpen}
              setSettingsModalOpen={setSettingsModalOpen}
              onHome={() => navigate('/')}
              onDownload={onDownloadComparison}
              onDownloadQuoteSummary={onDownloadQuoteSummary}
              handleFileChange={handleFileChange}
              documents={documents}
              setDeleting={setDeleting}
              setShowDeletingToast={setShowDeleteToast}
              retrieveUserInfo={retrieveUserInfo}
              agencyId={agencyId}
            />
          }
        />
      </Routes>
      <CreateClientModal setModalOpen={setCreateClientModalOpen} modalOpen={createClientModalOpen} setClientId={setClientId} setClients={setClients} retrieveAllClients={refetchClients} />
      <SettingsModal setModalOpen={setSettingsModalOpen} modalOpen={settingsModalOpen} setSuccessMessage={setSuccessMessage} setErrorMessage={setErrorMessage} /> 
      {showUploadToast &&
        <Toast>
          {uploading && <><LoadingSpinner/><ToastText>Documents uploading...</ToastText></>}
          {(!uploading) && 
            <>
              <ToastDocumentAction 
                successful={successfulUploads.map(s => documents.find(doc => doc.instanceId === s)?.name || "")} 
                unsuccessful={errorUploads.map(s => documents.find(doc => doc.instanceId === s)?.name || "")} 
              />
              <Button text="Dismiss" handleClick={() => setShowUploadToast(false)}/>
            </>
          }
          {/* (!uploading && error) && <><ToastText>{error}</ToastText><Button text="Dismiss" handleClick={() => {
            setShowUploadToast(false);
            setError(null);
          }}/></>*/}
        </Toast>
      }
      {showProposalToast &&
        <Toast>
          {generatingProposal && <><LoadingSpinner/><ToastText>Creating proposal...</ToastText></>}
          {!generatingProposal && <><ToastText>Your proposal is generating. It will be ready shortly.</ToastText><Button text="Dismiss" handleClick={() => setShowProposalToast(false)}/></>}
        </Toast>
      }
      {showComparisonToast &&
        <Toast>
          {generatingComparison && <><LoadingSpinner/><ToastText>Creating comparison...</ToastText></>}
          {!generatingComparison && <><ToastText>Your comparison is generating. It will be ready shortly.</ToastText><Button text="Dismiss" handleClick={() => setShowComparisonToast(false)}/></>}
        </Toast>
      }
      {showDownloadToast &&
        <Toast>
          {downloading && <><LoadingSpinner/><ToastText>Document downloading...</ToastText></>}
          {!downloading && <><ToastText>Document downloaded</ToastText><Button text="Dismiss" handleClick={() => setShowDownloadToast(false)}/></>}
        </Toast>
      }
      {showSpreadsheetToast &&
        <Toast>
          {spreadsheetDownloading && <><LoadingSpinner/><ToastText>Spreadsheet downloading...</ToastText></>}
          {!spreadsheetDownloading && <><ToastText>Spreadsheet downloaded</ToastText><Button text="Dismiss" handleClick={() => setShowSpreadsheetToast(false)}/></>}
        </Toast>
      }
      {showDeleteToast &&
        <Toast>
          {deleting && <><LoadingSpinner/><ToastText>Document deleting...</ToastText></>}
          {!deleting && <><ToastText>Document deleted</ToastText><Button text="Dismiss" handleClick={() => setShowDeleteToast(false)}/></>}
        </Toast>
      }
      <ToastContainer />
    </>
  )
});

export default LandingPage;
