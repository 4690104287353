import styled from "styled-components";
import SIZING from "../../designSystem/sizing";
import COLORS from "../../designSystem/colors";
import { ButtonStyleProps } from "./types";
import { Icon } from "../Icon/styles";

export const ButtonContainer = styled.button<ButtonStyleProps>`
  display: flex;
  justify-content: flex-start;
  border-radius: ${SIZING.RADIUS.MEDIUM};
  height: ${({ $height }) => ($height ? $height : "min-content")};
  padding: ${({ $semilarge, $large, $square }) =>
    $large
      ? SIZING.SPACING.LARGE
      : $square
      ? `${SIZING.SPACING.STANDARD} auto`
      : $semilarge
      ? SIZING.SPACING.STANDARD
      : `${SIZING.SPACING.SMALL} ${SIZING.SPACING.STANDARD}`};
  vertical-align: middle;
  border: ${({ $borderless, $secondary, $borderColor }) =>
    $borderless
      ? "none"
      : `1px solid ${
          $borderColor
            ? $borderColor
            : $secondary
            ? COLORS.UI.BUTTON.SECONDARY.BORDER
            : COLORS.UI.BORDER.STANDARD
        }`};
  background-color: ${({ $secondary, $background }) =>
    $background
      ? $background
      : $secondary
      ? COLORS.UI.BUTTON.SECONDARY.BACKGROUND
      : COLORS.UI.BUTTON.PRIMARY.BACKGROUND};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? "80%" : "100%")};
  width: ${({ $grow }) => ($grow ? "100%" : "auto")};
  white-space: nowrap;
  aspect-ratio: ${({ $square }) => ($square ? "1 / 1" : "auto")};
  align-items: center;

  &:hover {
    opacity: 80%;
  }
  transition: background-color 0.1s;
  :first-child {
    margin-left: 0;
  }
  :last-child {
    margin-right: 0;
  }

  ${({ $square }) =>
    $square
      ? `
        img {
            margin: auto !important;
        }
        `
      : ""}
`;

export const Text = styled.span<ButtonStyleProps>`
  font-weight: ${({ $bold }) => ($bold ? "500" : "400")};
  color: ${({ $secondary, $color }) =>
    $secondary
      ? COLORS.UI.TEXT.SECONDARY
      : $color
      ? $color
      : COLORS.UI.TEXT.STANDARD};
  ${({ $grow }) =>
    $grow
      ? `
        text-align: left;
        flex: 1;
    `
      : ""}
  ${({ $semilarge, $large }) => ($large ? `font-size: 20px;` : $semilarge ? `font-size: 16px;` : "")}
`;

export const IconLeft = styled(Icon)`
  margin-right: ${SIZING.SPACING.SMALL};
`;

export const IconRight = styled(Icon)`
  margin-left: ${SIZING.SPACING.SMALL};
`;
