import { loadStripe } from '@stripe/stripe-js';
import { getPresignedUrl } from './requests';
import { DocumentType } from './components/Document';

export const handleDownload = async (d: DocumentType | undefined, cookie: string | undefined, window, pdfSummary?: boolean) => {
  // Fetch the PDF from the URL
  if (!d || !cookie) return;
  console.log(d.instanceId)
  const link = (!!pdfSummary ? d.pdfSummaryLink : d.link) ?? ""; 
  const resp = await getPresignedUrl(cookie, link, d.instanceId ?? "", (d.category == "comparison" || d.category == "analysis"), d.category == "proposal");

  console.log("URL RESPONSE FOR DOWNLOAD: " + resp.body["url"])
  const response = await fetch(resp.body["url"]);
  if (!response.ok) throw new Error('Failed to fetch PDF');
  
  // Create a Blob from the PDF stream
  const blob = await response.blob();
  // Create a local URL for the Blob
  const url = window.URL.createObjectURL(blob);
  
  // Create a temporary anchor element and trigger the download
  const a = window.document.createElement('a');
  a.href = url;
  a.download = link.endsWith(".xlsx")
    ? `${d.name.slice(0,70)}.xlsx`
    : d.category == "proposal" 
      ? `${d.name.slice(0,70)}.pptx`
      : `${d.name.slice(0,70)}.pdf`;
  window.document.body.appendChild(a); // Append to the document
  a.click(); // Trigger the download
  
  // Clean up
  window.URL.revokeObjectURL(url);
  window.document.body.removeChild(a);
};

export const isAutoVerified = (dateString: string | null | undefined) => {
  if (!dateString) {
    return true;
  }

  // Parse the input date string
  const inputDate = new Date(dateString);
  
  // Define the target date (December 2, 2024, 12:00 AM GMT)
  const targetDate = new Date('2024-12-01T00:00:00Z');
  //const targetDate = new Date('2023-12-01T00:00:00Z');

  return inputDate < targetDate;
};

export const convertDateFormat = (inputDate: string) => {
  // Parse the input date string to a Date object
  const date = new Date(inputDate);

  // Use Intl.DateTimeFormat to format the date
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
      month: 'long', // full month name
      day: 'numeric', // day of the month
      year: 'numeric', // 4 digit year
  });

  // Formatting the time part manually to get the hour in 12-hour format with am/pm
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? '0'+minutes : minutes;

  // Combine the formatted parts
  const formattedDate = `${dateFormatter.format(date)}, ${hours}:${minutesStr}${ampm}`;
  return formattedDate;
}

export const secondsSince = (inputDate: string) => {

  const reportInitiated = new Date(inputDate).getTime();
  const now = Date.now(); // Get the current timestamp in milliseconds
  const diffInMillis = now - reportInitiated; // Calculate the difference in milliseconds
  const diffInMinutes = diffInMillis / (1000); // Convert the difference to minutes

  return diffInMinutes;
}

export const extractEmail = (inputString: string) => {
  // Regular expression for matching an email address
  const emailRegex = /<([^>]+)>/;
  const match = inputString.match(emailRegex);

  // If an email is found, return it, otherwise return an empty string or null
  return match ? match[1] : null;
}

let stripePromise;
export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "");
  }
  return stripePromise;
};

export const makeCookie = (len: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < len) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const convertTimestampToDateStr = (timestampStr: string) => {
  // Create a new Date object from the timestamp string
  let date = new Date(timestampStr);

  // Define an array of month names
  const monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
  ];

  // Extract the year, month, and day from the date
  let year = date.getFullYear();
  let month = monthNames[date.getMonth()];
  let day = date.getDate();

  // Format and return the date string
  return `${month} ${day}, ${year}`;
}

export const validateCookie = async (cookie: string | null | undefined) => {

  if (!cookie) return false;

  const response = await fetch(
    `${process.env.REACT_APP_BE_URL}/validate-cookie`,
    { 
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "cookie": cookie
      })
    }
  );

  try {
    const respJson = await response.json();
    if (response.status !== 200) return "";
  
    return respJson["user_id"];
  } catch (e) {
    return "";
  }
}
// Usage example: copyImageInS3('path/to/sourceImage.jpg', 'path/to/destinationImage.jpg', 'destination-bucket-name');

export const difference = (date: Date) =>{
  const now = new Date();
  const diffInMs = date.getTime() - now.getTime();
  
  const diffInSecs = Math.floor(diffInMs / 1000);
  const diffInMins = Math.floor(diffInSecs / 60);
  const hours = Math.floor(diffInMins / 60);
  const secs = diffInSecs % 60;
  const mins = diffInMins % 60;

  return { hours, mins, secs }
}
