import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import SelectInput from "../SelectInput";
import {
  EditableTextWrapper,
  HiddenInput,
  NavbarCenter,
  NavbarContainer,
  NavbarLeft,
  NavbarRight,
  NavbarTitleEdit,
  OptionWrapper,
} from "./styles";
import Button from "../Button";
import TextInput from "../TextInput";
import Logo from "../../assets/icons/Logo.svg";
import Cog from "../../assets/icons/Cog.svg";
import Search from "../../assets/icons/Search.svg";
import Upload from "../../assets/icons/Upload.svg";
import CollapseIcon from "../../assets/icons/CollapseIcon.svg";
import Check from "../../assets/icons/Check.svg";
import RedX from "../../assets/icons/RedX.svg";
import PenLine from "../../assets/icons/PenLine.svg";
import { NavbarProps, UploadActions } from "./types";
import { Option } from "../SelectInput/types";
import { Client } from "../../types/Client";
import Refresh from "../../assets/icons/Refresh.svg";
import { Icon } from "../Icon/styles";
import EditableText from "../EditableText";
import { useCookies } from "react-cookie";
import { setDisplayName } from "../../requests";
import { Pages } from "../../LandingPage/types";
import Dropdown from "../Dropdown";
import Ellipsis from "../../assets/icons/Ellipsis.svg";
import Trash from "../../assets/icons/Trash.svg";
import CreateProposal from "../CreateProposal";
import Sparkle from "../../assets/icons/Sparkles.svg";
import Plus from "../../assets/icons/Plus_white.svg";

export const staticClients = [
  {
    id: "default_client",
    clientId: "default_client",
    name: "General",
    userd: "default_client",
    currentClientId: "default_client",
  },
];

const AppHeader: React.FC<NavbarProps> = ({
  clientId,
  setClientId,
  clients,
  setCreateClientModalOpen,
  setSettingsModalOpen,
  onSearch,
  onHome,
  document,
  handleFileChange,
  handleRegenerateComparison,
  retrieveDocuments,
  page,
  handleDelete,
  handleCreateProposal,
  handleComparePolicy,
  sticky
}) => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["user-id"]);

  const [localClients, setLocalClients] = useState<Client[]>(staticClients);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredClients, setFilteredClients] = useState<any>(staticClients);
  const [localDocument, setLocalDocument] = useState<DocumentType | null>();
  const [comparisonMenuOpen, setComparisonMenuOpen] = useState<boolean>(false);

  const dropdownOptions = [
    { label: "Regenerate", onClick: () => {}, icon: Refresh },
    {
      label: "Delete",
      onClick: () => {
        handleDelete && document && handleDelete(document);
      },
      icon: Trash,
    },
  ];

  useEffect(() => {
    if (localClients) {
      const filteredList = localClients.filter((localClient) =>
        localClient.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      filteredList
        ? setFilteredClients(filteredList)
        : setFilteredClients(staticClients[0]);
    }
  }, [searchTerm, localClients]);

  useEffect(() => {
    const updatedClients = clients.concat(staticClients);
    setLocalClients(updatedClients);
  }, [clients]);

  const handleSelectChange = (id) => {
    localStorage.setItem("selectedClientId", id ?? "");
    setClientId(id ?? "");
    navigate("/");
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleNameSave = async (newName: string) => {
    await setDisplayName(
      cookies["user-id"],
      document?.instanceId ?? "",
      newName,
      document?.category != "policy"
    );
    retrieveDocuments && retrieveDocuments();
  };

  const handleSearch = (val: string) => {
    setSearchTerm(val);
  };

  const mapClientsToOption = (clients: Client[]): Option[] => {
    return clients.map((client) => ({
      label: client.name,
      value: client.clientId,
    }));
  };

  // TODO good client for refactoring
  const getSelectedClient = (): Option => {
    const selected = localClients.find(
      (client) => client.clientId === clientId
    );
    const val = selected
      ? mapClientsToOption([selected])[0]
      : mapClientsToOption(staticClients)[0];
    return val;
  };

  return (
    <NavbarContainer sticky={!!sticky}>
      <NavbarLeft>
        <OptionWrapper>
          <Button $secondary iconLeft={Logo} handleClick={onHome} />
          <SelectInput
            id="dashboardtour-step7"
            options={mapClientsToOption(filteredClients)}
            icon={CollapseIcon}
            $grow
            callback={handleSelectChange}
            addClient={setCreateClientModalOpen}
            searchCallback={handleSearch}
            initial={getSelectedClient()}
          />
        </OptionWrapper>
        <OptionWrapper>
          <Button
            iconLeft={Cog}
            handleClick={() => setSettingsModalOpen(true)}
          />
        </OptionWrapper>
      </NavbarLeft>
      <NavbarCenter>
        {onSearch && (
          <TextInput
            id="dashboardtour-step22"
            icon={Search}
            placeholder="Search"
            $grow
            onChangeCallback={onSearch}
          />
        )}
        {document?.name && (
          <EditableTextWrapper>
            <EditableText
              defaultValue={document.name}
              onSubmitChange={(newName) => handleNameSave(newName)}
            />
          </EditableTextWrapper>
        )}
      </NavbarCenter>
      <NavbarRight>
        <div style={{ color: "#4455F3", cursor: "pointer", marginTop: "20px", transform: "translateX(-10px)" }} onClick={() => window.open("https://dramatic-bull-199.notion.site/Coverflow-User-Guide-1abbc64e157f801a9b22d1f3fac2b3cf?pvs=4", "_blank")}>Website Guide</div>
        {handleCreateProposal && (
          <Button
            iconLeft={Sparkle}
            handleClick={handleCreateProposal}
            text="Create Proposal"
          />
        )}
        {handleComparePolicy && (
          <Button
            iconLeft={Plus}
            handleClick={handleComparePolicy}
            text="Compare Policies"
            $secondary
          />
        )}
        {page !== Pages.COMPARISON && (
          <>
            {!document?.name && handleFileChange && (
              <>
                <Button
                  iconLeft={Upload}
                  text="Upload"
                  handleClick={handleUpload}
                />
                <HiddenInput
                  type="file"
                  multiple
                  ref={fileInputRef}
                  onChange={(e) => handleFileChange(e)}
                />
              </>
            )}
            {document?.name &&
              handleFileChange &&
              !document?.associatedDocumentId &&
              !handleRegenerateComparison && (
                <>
                  <Button
                    iconLeft={Upload}
                    text="Upload Endorsement"
                    handleClick={handleUpload}
                  />
                  <HiddenInput
                    type="file"
                    multiple
                    ref={fileInputRef}
                    onChange={(e) =>
                      handleFileChange(e)
                    }
                  />
                </>
              )}
            {document && handleRegenerateComparison && (
              <Button
                text="Regenerate Comparison"
                iconLeft={Refresh}
                handleClick={handleRegenerateComparison}
              />
            )}
          </>
        )}
        {page === Pages.COMPARISON && (
          <Dropdown
            icon={Ellipsis}
            options={dropdownOptions}
            value={comparisonMenuOpen}
            openStateCallback={(val: boolean) => setComparisonMenuOpen(val)}
            $alignRight
            $grow={false}
            key={"Comparison"}
          />
        )}
      </NavbarRight>
    </NavbarContainer>
  );
};

export default AppHeader;
