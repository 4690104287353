import styled, { css, keyframes } from "styled-components";
import COLORS from "../../designSystem/colors";
import SIZING from "../../designSystem/sizing";
import { DropzoneProps, TrafficLightProps } from "./types";
import colors from "../../designSystem/colors";

export const TableContainer = styled.div<DropzoneProps>`
  flex: 1;
  border: 1px ${({ $dashedBorder }) => ($dashedBorder ? "dashed" : "solid")}
    ${COLORS.UI.BORDER.STANDARD};
  border-radius: ${SIZING.RADIUS.MEDIUM};
  width: ${({ $width }) => ($width ? $width : "min-content")};
  height: ${({ $height }) => ($height ? $height : "min-content")};
  background-color: ${COLORS.UI.BACKGROUND.ELEMENT};
  transition: background-color 0.3s, color 0.3s, height 0.3s, border 250ms ease;
  background-color: ${COLORS.UI.BACKGROUND.ACCENT};
  width: 100%;
  box-sizing: border-box;
  ${({ $marginTop }) => ($marginTop ? `margin-top: ${$marginTop};` : "")}

  ${({ isDragging }) =>
    isDragging
      ? `
            border: 1px solid ${COLORS.UI.BORDER.SELECTED};
            box-sizing: border-box;
    `
      : ""}
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  position: relative;
`;

export const THead = styled.thead``;

export const Tr = styled.tr`
  border-bottom: 1px solid ${COLORS.UI.BORDER.STANDARD};
  border-collapse: collapse;
`;

export const Th = styled.th`
  padding: ${SIZING.SPACING.LARGE} ${SIZING.SPACING.SMALL};
  text-align: left;
  font-weight: 500;
  cursor: pointer;
`;

export const TBody = styled.tbody<DropzoneProps>`
  background-color: ${({ isDragging }) =>
    isDragging ? COLORS.UI.BACKGROUND.SELECTED : COLORS.UI.BACKGROUND.STANDARD};
  transition: background-color 250ms ease;
  &:last-child {
    border-bottom: none;
  }
`;

export const BodyTr = styled(Tr)`
  transition: background-color 150ms ease;
  &:hover {
    background-color: ${COLORS.UI.BACKGROUND.ACTIVE};
    cursor: pointer;
  }
`;

export const Td = styled.td`
  padding: ${SIZING.SPACING.SMALL};
  border-collapse: collapse;
`;

export const LinkedTd = styled.td`
  padding: ${SIZING.SPACING.SMALL};
  border-collapse: collapse;
  max-width: 200px;
`;

export const VerifiedTd = styled.td`
  padding: ${SIZING.SPACING.SMALL};
  border-collapse: collapse;
  max-width: 10px;
`;

export const NameTd = styled.td`
  padding: ${SIZING.SPACING.SMALL};
  border-collapse: collapse;
`;

export const StatusTd = styled.td`
  padding: ${SIZING.SPACING.SMALL};
  border-collapse: collapse;
`;

const pulse = keyframes`
  0% { background-color: Orange; } 
  100% { background-color: ${colors.UI.TEXT.MEDIUM_DARK_GREY};
`;

export const TrafficLight = styled.div<TrafficLightProps>`
  display: inline-block;
  background-color: ${({ status }) =>
    ["processed", "generated", "analyzed"].includes(status.toLowerCase())
      ? "#038750"
      : status === "failed"
      ? "red"
      : "Orange"};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: ${SIZING.SPACING.STANDARD};
  ${({ status }) =>
    ["processed", "generated", "analyzed", "failed"].includes(
      status.toLowerCase()
    ) === false
      ? css`
          animation: ${pulse} 1s infinite alternate ease-in;
        `
      : ""}
`;

export const ThDocDescription = styled(Th)`
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const ThType = styled(Th)`
  min-width: 70px; // to account for other pill sizes
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
`;

export const InitialWrapper = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  flex-direction: column;
`;

export const DragInfoContainer = styled.div`
  position: fixed;
  bottom: ${SIZING.SPACING.DOUBLE_LARGE};
  display: flex;
  width: 100%;
  justify-content: space-around;
  left: 0;
  right: 0;
  z-index: 100;
`;

export const DragInfoTextWrapper = styled.div`
  display: block;
`;

export const DragInfoHeader = styled.p`
  font-weight: 500;
  margin: 0;
  padding: 0;
  padding-bottom: ${SIZING.SPACING.SMALLEST};
`;

export const DragInfoSubHeader = styled.p`
  font-weight: 400;
  color: ${COLORS.UI.TEXT.LIGHT};
  margin: 0;
  padding: 0;
`;

export const DragInfoCard = styled.div<DropzoneProps>`
  background-color: ${COLORS.UI.BACKGROUND.STANDARD};
  padding: ${SIZING.SPACING.LARGE};
  border: 1px solid ${COLORS.UI.BORDER.STANDARD};
  border-radius: ${SIZING.RADIUS.MEDIUM};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  animation: ${({ isDragging }) => (isDragging ? slideIn : "")} 0.3s ease-out;
  display: flex;
  align-items: center;

  & > img {
    max-height: min-content;
    margin-right: ${SIZING.SPACING.STANDARD};
  }
`;

const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const ToastText = styled.p`
  margin: 0;
  padding: 0;
`;

export const LinkedContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;
