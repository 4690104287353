import React from 'react';
import { DocumentActionContainer, Heading, NameContainer, DocumentName, ActionIcon } from './styles';
import { ToastDocumentActionProps } from './types';
import File2 from '../../assets/icons/File2.svg';

const ToastDocumentAction: React.FC<ToastDocumentActionProps> = ({ successful, unsuccessful}) => {
    return(
    <DocumentActionContainer>
        <NameContainer>
            <ActionIcon src={File2} alt="file_icon"/>
            <div style={{ display: "flex", flexDirection: "column"}}>
            { successful.length > 0 &&
                <>
                    <Heading>Documents Uploaded:</Heading>
                    <br/>
                    { successful.map(s => 
                        <>
                            <DocumentName>{s}</DocumentName>
                            <br/>
                        </>
                    )}
                </>
            }
            { unsuccessful.length > 0 &&
                <>
                    <Heading>Documents Not Uploaded:</Heading>
                    { unsuccessful.map(s => <DocumentName>{s}</DocumentName>)}
                </>
            }
            </div>
        </NameContainer>
    </DocumentActionContainer>
)}

export default ToastDocumentAction;