import React from "react";
import { CardButtonProps } from "./types";
import { CardButtonContainer, Content, Header, TextContainer } from "./styles";
import { Icon } from "../Icon/styles";
import Plus from "../../assets/icons/Plus.svg";

const CardButton: React.FC<CardButtonProps> = ({
  handleClick,
  buttonIcon,
  header,
  content,
}) => {
  return (
    <CardButtonContainer onClick={handleClick}>
      <Icon height="64px" alt="button icon" src={buttonIcon} />
      <TextContainer>
        <Header>{header}</Header>
        <Content>{content}</Content>
      </TextContainer>
      <Icon height="24px" alt="plus" src={Plus} />
    </CardButtonContainer>
  );
};

export default CardButton;
