import React, { useEffect, useState } from "react";
import { ComparisonLoaderProps } from "./types";
import { Icon } from "../Icon/styles";
import Check from "../../assets/icons/Check.svg";
import Policy from '../../assets/icons/Policy.svg';
import BlueCircle from '../../assets/icons/BlueCircle.svg';
import DownloadWhite from "../../assets/icons/DownloadWhite.svg";
import ColoredText from "../ColoredText";
import { ComparisonLoaderContainer, LoaderSubText, LoadingStep, LoadingStepsContainer } from "./styles";
import { CircularProgress } from "@mui/material";
import Button from "../Button";

const ComparisonLoader: React.FC<ComparisonLoaderProps> = ({
  document,
  onDownload,
  onDownloadQuoteSummary,
  agencyId,
  subtitle
}) => {
  const [loaderStateIndex, setLoaderStateIndex] = useState<number>(0);
  const [loaderStates, setLoaderStates] = useState<string[]>([]);

  useEffect(() => {
    if (document?.createdAt) {
      const createdAt = new Date(document.createdAt);
  
      const now = new Date();
      const diffMs = now.getTime() - createdAt.getTime();
      const diffMinutes = Math.floor(diffMs / (1000 * 60));
  
      setLoaderStateIndex(
        document?.status == "analyzing" 
          ? Math.min(diffMinutes, loaderStates.length - 1)
          : loaderStates.length
      );
    }
  }, [document, loaderStates])

  useEffect(() => {
    setLoaderStates([
      "Preparing Analysis",
      ...document?.instanceIds?.map((id, i) => `Analyzing Document ${i + 1}`) ?? "",
      "Matching Data Points",
      "Finalizing Comparison"
    ]);
  }, [document])

  return (
    <ComparisonLoaderContainer>
      <div style={{ fontSize: "30px" }}>
        { document?.status == "analyzing" && "Comparison Generating..." }
        { document?.status != "analyzing" && "Comparison Generated" }
      </div>
      { document?.status != "analyzing" && 
          <Button
            iconLeft={DownloadWhite}
            text={"Download Excel"}
            handleClick={() => onDownload(document ?? undefined)}
            $secondary
            $semilarge
          />
      }
      { (document?.status != "analyzing" && document?.instanceIds && document?.instanceIds.length < 4 && agencyId == process.env.REACT_APP_AGENCY_VA_ID) && 
          <Button
            iconLeft={DownloadWhite}
            text={"Download PDF"}
            handleClick={() => onDownloadQuoteSummary(document ?? undefined)}
            $secondary
            $semilarge
          />
      }
      <LoaderSubText>
        { document?.status == "analyzing" && "This might take a few minutes—we'll email you when it's done." }
        { (document?.status != "analyzing") && (subtitle ? subtitle : "We don't support viewing comparisons of 3+ policies in app, but you can download above.") }
      </LoaderSubText>
      { document?.status == "analyzing" && 
        <Icon
          src={Policy}
          height="50px"
          alt="policy"
        />
      }
      <LoadingStepsContainer>
        { loaderStates.map((l, i) => (
          <LoadingStep loaded={i < loaderStateIndex}>
            { i < loaderStateIndex && 
              <Icon
                src={Check}
                alt="checkmark"
                height="24px"
              />
            }
            { i == loaderStateIndex && 
              <CircularProgress size={20} />
            }
            { i > loaderStateIndex && 
              <Icon
                src={BlueCircle}
                alt="checkmark"
                height="24px"
              />
            }
            <div style={{ fontSize: "18px"}}>{l}</div>
          </LoadingStep>
        ))}
      </LoadingStepsContainer>
    </ComparisonLoaderContainer>
  )
}

export default ComparisonLoader;