import React, { useEffect, useRef, useState } from 'react';

import { Header, SubHeader } from '../../Header/styles';
import ChevronRight from '../../../assets/icons/ChevronRight.svg';
import Text from '../../Text';
import { SelectFirstPolicyProps, UpsellPolicyProps } from '../types';
import { ButtonWrapper, ComparisonWrapper, HeaderWrapper, MyFilesToggle, ProgressDisplay, SearchWrapper, SelectedDocumentsWrapper, SelectionWrapper } from '../styles';
import DocumentWizardTable from '../../DocumentWizardTable';
import Button from '../../Button';
import Close from '../../../assets/icons/x-close.svg'
import CloseActive from '../../../assets/icons/Close_Active.svg'
import FileActive from '../../../assets/icons/File_active.svg'
import COLORS from '../../../designSystem/colors';
import { DocumentType } from '../../Document';
import Space from '../../base/Space';
import { Icon } from '../../Icon/styles';
import { DocumentDescriptionContainer, Row, StatusContainer } from '../../DocumentWizardTable/styles';
import DocumentDescription from '../../DocumentDescription';
import Pill from '../../Pill';
import { PillType } from '../../Pill/types';
import CheckboxInput from '../../CheckboxInput';

const UpsellPolicy: React.FC<UpsellPolicyProps> = ({ rows, handleSelect, handleContinue, handleCancel, progressBlocked, selected }) => {

    return(
        <>
            <HeaderWrapper>
                <ProgressDisplay>
                    <Text content="SELECT POLICIES" />
                    <Icon src={ChevronRight} alt="Progress Icon" />
                    <Text content="SET NAME" />
                    <Icon src={ChevronRight} alt="Progress Icon" />
                    <Text content="UPSELL" />
                </ProgressDisplay>
                <Button iconLeft={Close} handleClick={handleCancel} $borderless/>
            </HeaderWrapper>
            <SubHeader>Select a policy to upsell</SubHeader>
            <Space px={16} />
            {rows.map(d => (
                <Row key={d.instanceId}>
                    <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between"}}>
                        <div style={{ marginRight: "20px"}}>
                            <CheckboxInput key={`${JSON.stringify(d.instanceId)}_upsellPolicy`}
                                value={selected === d.instanceId}
                                callback={() => handleSelect(d.instanceId || "")}
                            />
                        </div>
                        <DocumentDescriptionContainer>
                            <DocumentDescription type={d.category} title={d.name}/>
                        </DocumentDescriptionContainer>
                        <StatusContainer>
                            <Pill type={d.websiteReady ? PillType.GENERATED : d.status} $greyscale />
                        </StatusContainer>
                    </div>
                </Row>
            ))}


            <ButtonWrapper>
                <Button text="Cancel" handleClick={handleCancel}/>
                <Button disabled={progressBlocked} text="Continue" handleClick={handleContinue} $secondary/>
            </ButtonWrapper>
        </>
    );
}

export default UpsellPolicy;