import React, { useEffect, useRef, useState } from 'react';
import { UploadAdditionalDocumentProps } from './types';
import Search from '../../assets/icons/Search.svg';
import DownCarat from "../../assets/icons/DownCarat.svg";
import { DocumentWizardTableContainer, ExpandableSection } from '../DocumentWizardTable/styles';
import { SelectedDocumentsWrapper } from '../PolicyComparisonWizard/styles';
import COLORS from '../../designSystem/colors';
import CloseActive from '../../assets/icons/Close_Active.svg'
import FileActive from '../../assets/icons/File_active.svg';
import CheckboxInput from '../CheckboxInput';
import DocumentDescription from '../DocumentDescription';
import Pill from '../Pill';
import PaginationControls from '../PaginationControl';
import { DocumentType } from '../Document';
import { SubHeader } from '../Header/styles';
import { PillType } from '../Pill/types';
import { Icon } from '../Icon/styles';
import { MyFilesToggle, SearchWrapper } from '../PolicyComparisonWizard/styles';
import Upload from "../../assets/icons/Upload.svg";
import { InitialWrapper, TableContainer } from '../DocumentTable/styles';

import TextInput from '../TextInput';
import Button from '../Button';
import { HiddenInput } from '../FileUploadButton/styles';
import { deleteDocument } from '../../requests';
import { useCookies } from 'react-cookie';

const UploadAdditionalDocuments: React.FC<UploadAdditionalDocumentProps> = (
    { documents, handleFileChange, policyId, retrieveDocuments }
) => {
    const [cookies, setCookie, removeCookie] = useCookies(['user-id']);

    const [show, setShow] = useState<boolean>(false);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [selected, setSelected] = useState<DocumentType[]>([]);

    const handleDragOver = (event: React.DragEvent) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = async (event: React.DragEvent) => {
        event.preventDefault();
        setIsDragging(false);

        handleFileChange(event, "endorsement", policyId);
    };


    const fileInputRef = useRef<HTMLInputElement>(null);
    
    const handleUpload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    useEffect(() => {
        setShow(false);
    }, [policyId])

    useEffect(() => {
        setSelected(documents.filter(d => d.associatedDocumentId == policyId));
    }, [documents, policyId])

    const handleRemoveFromSelected = async (document: DocumentType) => {
        setSelected(prev => prev.filter(p => p.instanceId != document.instanceId));
        await deleteDocument(cookies['user-id'], document.instanceId ?? "", false, false);
        retrieveDocuments && retrieveDocuments();
    }


    return (
        <DocumentWizardTableContainer>
            <MyFilesToggle onClick={() => setShow(!show)}>
                Attach Additional Endorsements (Optional)
                <Icon src={DownCarat} alt="downcarat" />
            </MyFilesToggle>
            <ExpandableSection style={{ maxHeight: show ? "600px" : "0px", padding: show ? "16px" : "0px 16px" }}>
                <TableContainer
                    isDragging={isDragging}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    $height="200px"
                    $width="100%"
                    $dashedBorder
                >
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "16px", height: "100%", width: "100%"}}>
                        <div style={{ fontSize: "20px", fontWeight: "500" }}>Drag and drop your files</div>
                        <div style={{ fontSize: "16px", fontWeight: "400" }}>or</div>
                        <Button
                            iconLeft={Upload}
                            text="Upload"
                            handleClick={handleUpload}
                        />
                        <HiddenInput
                            type="file"
                            ref={fileInputRef}
                            multiple
                            onChange={(e) => handleFileChange(e, "endorsement", policyId)}
                        />
                    </div>
                </TableContainer>
                {selected[0] && (
                    <SelectedDocumentsWrapper>
                        {selected.map((document, index) => {
                            return (
                                <Button
                                    key={index}
                                    handleClick={() => handleRemoveFromSelected(document)}
                                    iconLeft={FileActive}
                                    text={document.name.length < 60 ? document.name : document.name.slice(0,57) + "..."}
                                    iconRight={CloseActive}
                                    $color={COLORS.UI.BORDER.SELECTED}
                                    $background={COLORS.UI.BACKGROUND.SELECTED} />
                            );
                        })}    
                    </SelectedDocumentsWrapper>
                )}
            </ExpandableSection>
        </DocumentWizardTableContainer>
    );
}

export default UploadAdditionalDocuments;