import React from 'react';
import { ButtonProps } from './types';
import { ButtonContainer, IconLeft, IconRight, Text } from './styles';

const Button: React.FC<ButtonProps> = ({ id, handleClick, iconLeft, text, iconRight, $secondary, $bold = true, $borderless, $grow, $color, disabled, $background, $large, $borderColor, $height, $semilarge }) =>
        <ButtonContainer
            id={id}
            onClick={(e) => {
                e.stopPropagation();
                handleClick(e);  
            }}
            $secondary={$secondary}
            $bold={$bold}
            $borderless={$borderless}
            $grow={$grow}
            $square={!text && !iconRight}
            disabled={disabled}
            $background={$background}
            $large={$large}
            $borderColor={$borderColor}
            $height={$height}
            $semilarge={$semilarge}
        >
            {iconLeft && <IconLeft src={iconLeft} height={$large ? "30px" : undefined} alt="button icon left"/>}
            {text && <Text $secondary={$secondary} $bold={$bold} $color={$color} $grow={$grow} $large={$large} $semilarge={$semilarge}>{text}</Text>}
            {iconRight && <IconRight src={iconRight} height={$large ? "30px" : undefined} alt="button icon right"/>}
        </ButtonContainer>

export default Button;