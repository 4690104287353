import React, { useState } from 'react';
import { useCookies } from 'react-cookie';

import Text from '../../Text';
import { ButtonWrapper, ProgressDisplay, SelectComparisonButtonWrapper } from '../styles';
import ChevronRight from '../../../assets/icons/ChevronRight.svg';
import { Icon } from '../../Icon/styles';
import Close from '../../../assets/icons/x-close.svg'
import { SubHeader } from '../../Header/styles';
import { SelectNameProps } from '../types';
import { HeaderWrapper } from '../../CreateProposal/styles';
import TextInput from '../../TextInput';
import Button from '../../Button';
import { DocumentDescriptionContainer, Row, StatusContainer } from '../../DocumentWizardTable/styles';
import DocumentDescription from '../../DocumentDescription';
import Pill from '../../Pill';
import { PillType } from '../../Pill/types';
import Space from '../../base/Space';

const SelectName: React.FC<SelectNameProps> = ({ defaultName, documents, onBack, onComplete, handleCancel, proposal, agencyId }) => {
  const [name, setName] = useState<string>(defaultName);
  const [createdFor, setCreatedFor] = useState<string>("");

  return(
    <>
      <HeaderWrapper>
          <ProgressDisplay>
              <Text content="SELECT POLICIES" />
              <Icon src={ChevronRight} alt="Progress Icon" />
              <Text content="SET NAME" />
          </ProgressDisplay>
          <Button iconLeft={Close} handleClick={handleCancel} $borderless/>
      </HeaderWrapper>
      <SubHeader>{ proposal ? "Proposal" : "Comparison"} Details</SubHeader>
      <Space px={16} />
      <div>Document name</div>
      <Space px={4} />
      <TextInput $grow initialValue={defaultName} onChangeCallback={(s) => setName(s)} />
      <Space px={16} />
      { (agencyId == process.env.REACT_APP_AGENCY_VA_ID) && (
        <>
          <div>Who is this being created for?</div>
          <Space px={4} />
          <TextInput $grow initialValue={""} onChangeCallback={(s) => setCreatedFor(s)} />
          <Space px={16} />
        </>
      )}
      <div style={{ border: "1px solid #DFE1E6", borderRadius: "6px" }}>
        <div style={{ padding: "12px", borderBottom: "1px solid #DFE1E6" }}>
          Files
        </div>
        {documents.map(d => (
          <Row key={d.instanceId}>
            <DocumentDescriptionContainer>
                <DocumentDescription type={d.category} title={d.name}/>
            </DocumentDescriptionContainer>
            <StatusContainer>
                <Pill type={d.websiteReady ? PillType.GENERATED : d.status} $greyscale />
            </StatusContainer>
          </Row>
        ))}
      </div>
      <Space px={16} />

      <ButtonWrapper>
        <Button text="Back" handleClick={onBack}/>
        <Button text={proposal ? `Create Proposal` : `Continue`} handleClick={() => onComplete(name, createdFor)} $secondary/>
      </ButtonWrapper>
    </>
  );
}

export default SelectName;