import styled from "styled-components";
import SIZING from "../../designSystem/sizing";
import COLORS from "../../designSystem/colors";
import { SidebarStyleProps } from "./types";
import colors from "../../designSystem/colors";

export const SideBarContainer = styled.div<SidebarStyleProps>`
  width: 240px;
  > p {
    margin-left: ${SIZING.SPACING.STANDARD};
    color: ${COLORS.UI.TEXT.GREY};
  }

  > * {
    margin-bottom: ${SIZING.SPACING.STANDARD};
  }

  background-color: ${colors.UI.BACKGROUND.STANDARD};
`;
