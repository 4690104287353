import React from "react";
import { RoundButtonProps } from "./types";
import { RoundButtonContainer } from "./styles";
import { Icon } from "../Icon/styles";

const RoundButton: React.FC<RoundButtonProps> = ({ handleClick, icon, rotate }) => {
  return (
    <RoundButtonContainer onClick={handleClick} rotate={rotate}>
      <Icon height="32px" alt="Button Icon" src={icon} />
    </RoundButtonContainer>
  );
};

export default RoundButton;
