import React, { useEffect, useRef, useState } from "react";
import { ScrollableCardOptionsProps } from "./types";
import {
  BackButtonContainer,
  CardWrapper,
  ForwardButtonContainer,
  ScrollableCardOptionsContainer,
  Wrapper,
} from "./styles";
import FileCard from "../FileCard";
import DocumentLarge from "../../assets/icons/File_Large.svg";
import Presentation from "../../assets/icons/Presentation.svg";
import RoundButton from "../RoundButton";
import ChevronRightLarge from "../../assets/icons/ChevronRightLarge.svg";

const ScrollableCardOptions: React.FC<ScrollableCardOptionsProps> = ({
  options,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<(HTMLDivElement | null)[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);
  const [moved, setMoved] = useState(false);

  const checkOverflow = () => {
    if (scrollRef.current) {
      setIsOverflowing(
        scrollRef.current.scrollWidth > scrollRef.current.clientWidth
      );
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [options]);

  const handleMouseDown = (e: React.MouseEvent) => {
    if (isDragging) {
      e.preventDefault();
      e.stopPropagation();
    }
    e.preventDefault();
    setMoved(false);
    setIsDragging(true);
    setStartX(e.pageX - (scrollRef.current?.offsetLeft || 0));
    setScrollLeft(scrollRef.current?.scrollLeft || 0);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) return;
    e.preventDefault();

    const x = e.pageX - (scrollRef.current?.offsetLeft || 0);

    const walkX = x - startX;
    if (Math.abs(walkX) > 5) {
      setMoved(true);
    }

    if (scrollRef.current) {
      scrollRef.current.scrollLeft = scrollLeft - walkX;
    }
  };

  const handleMouseUp = (e: React.MouseEvent) => {
    if (isDragging) {
      e.preventDefault();
      e.stopPropagation();
    }
    setIsDragging(false);
  };

  const scrollToNextItem = () => {
    if (scrollRef.current && itemsRef.current.length > 0) {
      const container = scrollRef.current;
      const currentScroll = container.scrollLeft;

      const nextItem = itemsRef.current.find((item) => {
        if (!item) return false;
        return item.offsetLeft > currentScroll;
      });

      if (nextItem) {
        container.scrollTo({
          left: nextItem.offsetLeft,
          behavior: "smooth",
        });
      }
    }
  };

  const scrollToPreviousItem = () => {
    if (scrollRef.current && itemsRef.current.length > 0) {
      const container = scrollRef.current;
      const currentScroll = container.scrollLeft;

      const previousItems = itemsRef.current.filter((item) => {
        if (!item) return false;
        return item.offsetLeft < currentScroll;
      });

      const previousItem = previousItems[previousItems.length - 1];

      if (previousItem) {
        container.scrollTo({
          left: previousItem.offsetLeft,
          behavior: "smooth",
        });
      }
    }
  };

  const handleOptionClick = (next: () => void) => {
    if (moved) return;
    next();
  };

  return (
    <Wrapper>
      <ScrollableCardOptionsContainer
        ref={scrollRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseUp}
        onMouseUp={handleMouseUp}
      >
        {options.map((option, index) => {
          return (
            <CardWrapper ref={(el) => (itemsRef.current[index] = el)}>
              <FileCard
                handleClick={() => handleOptionClick(option.handleClick)}
                key={index}
                fileIcon={
                  option.type === "Comparison" ? DocumentLarge : Presentation
                }
                header={option.header}
                fileNames={option.linkedFiles}
                differencesCount={option.differencesCount}
                loading={option.loading}
              />
            </CardWrapper>
          );
        })}
        {isOverflowing && (
          <>
            <BackButtonContainer>
              <RoundButton
                handleClick={scrollToPreviousItem}
                icon={ChevronRightLarge}
                rotate="180"
              />
            </BackButtonContainer>
            <ForwardButtonContainer>
              <RoundButton
                handleClick={scrollToNextItem}
                icon={ChevronRightLarge}
              />
            </ForwardButtonContainer>
          </>
        )}
      </ScrollableCardOptionsContainer>
    </Wrapper>
  );
};

export default ScrollableCardOptions;
