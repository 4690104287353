import React, { useEffect, useState } from 'react';
import { DocumentWizardTableProps, RowType } from './types';
import Search from '../../assets/icons/Search.svg';
import DownCarat from "../../assets/icons/DownCarat.svg";
import { DocumentWizardTableContainer, HeaderRow, Cell, Row, DocumentDescriptionContainer, StatusContainer, StatusContainerHeader, FixedHeightRowContainer, NoResultsWrapper, ExpandableSection } from './styles';
import CheckboxInput from '../CheckboxInput';
import DocumentDescription from '../DocumentDescription';
import Pill from '../Pill';
import PaginationControls from '../PaginationControl';
import { DocumentType } from '../Document';
import { SubHeader } from '../Header/styles';
import { PillType } from '../Pill/types';
import { Icon } from '../Icon/styles';
import { MyFilesToggle, SearchWrapper } from '../PolicyComparisonWizard/styles';
import TextInput from '../TextInput';

const DocumentWizardTable: React.FC<DocumentWizardTableProps> = ({ rows = [], allRows = [], chosen, onSelect, singleSelect, handleSearch, showMyFiles, setShowMyFiles }) => {
    //const [selected, setSelected] = useState<string[]>([]);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 3;
    //const itemsPerPage = 3;

    useEffect(() => {
        setCurrentPage(1);
    }, [rows]);

    useEffect(() => {

    }, [chosen])

    const paginatedRows = rows.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    
    const totalPages = Math.ceil(rows.length / itemsPerPage);

    const handlePageChange = (newPage: number) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleSelect = (rowId: string) => {
        if (singleSelect) {
            //setSelected([rowId]);
            const element = rows.find((e) => ( e.instanceId === rowId ));
            if (element) onSelect([element]);
            else onSelect([]);

            return;
        }

        const selected = chosen.map(c => c.instanceId);
        const updatedSelected = selected.includes(rowId)
            ? selected.filter((id) => id !== rowId)
            : [...selected, rowId];

        const selectedRows: DocumentType[] = [];
        
        updatedSelected.forEach(selectedId => {
            const element = allRows.find((e) => ( e.instanceId === selectedId ));
            if (element) { selectedRows.push(element); }
        })
        onSelect(selectedRows);
    };

    return (
        <DocumentWizardTableContainer>
            <MyFilesToggle onClick={() => setShowMyFiles(!showMyFiles)}>
                My Files
                <Icon src={DownCarat} alt="downcarat" />
            </MyFilesToggle>
            <ExpandableSection style={{ maxHeight: showMyFiles ? "500px" : "0px" }}>
                <SearchWrapper>
                    <TextInput icon={Search} $grow placeholder='Search...' onChangeCallback={handleSearch}/>
                </SearchWrapper>
                <FixedHeightRowContainer>
                    {paginatedRows?.length === 0 && <NoResultsWrapper><SubHeader>No Results Found</SubHeader></NoResultsWrapper>}
                    {paginatedRows.map((row, rowIndex) => {
                        const globalRowId = row.instanceId!;
                        return (
                        <Row key={row.instanceId} $selected={!!chosen.find(c => c.instanceId == globalRowId!)}>
                            <DocumentDescriptionContainer>
                                <CheckboxInput key={`${JSON.stringify(chosen)}_${currentPage}`}
                                    value={!!chosen.find(c => c.instanceId == globalRowId!)}
                                    callback={() => handleSelect(globalRowId!)}
                                />
                                <DocumentDescription type={row.category} title={row.name}/>
                            </DocumentDescriptionContainer>
                            <StatusContainer>
                                <Pill type={row.websiteReady ? PillType.GENERATED : row.status} $greyscale />
                            </StatusContainer>
                        </Row>
                    )})}
                </FixedHeightRowContainer>
                <PaginationControls totalPages={totalPages} handlePageChange={(i) => handlePageChange(i)} currentPage={currentPage} arrayLength={rows.length} itemsPerPage={itemsPerPage} />
            </ExpandableSection>
        </DocumentWizardTableContainer>
    );
}

export default DocumentWizardTable