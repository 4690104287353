import React, { useCallback, useEffect, useRef, useState } from "react";
import { TextInputProps } from "./types";
import { Form, Input, TextInputContainer } from "./styles";
import { debounce } from "lodash";
import { Icon } from "../Icon/styles";
import Button from "../Button";
import Close from "../../assets/icons/x-close.svg";

const TextInput: React.FC<TextInputProps> = ({
  placeholder,
  icon,
  $grow,
  onChangeCallback,
  onClick,
  initialValue = "",
  disabled = false,
  nameEdit = false,
  $borderless,
  $noPadding,
  hideClear,
  id
}) => {
  const [value, setValue] = useState<string>(initialValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (value === event.target.value) {
      return;
    }

    setValue(newValue);
    onChangeCallback && debouncedCallback(newValue);
  };

  const handleReset = () => {
    // e.preventDefault();
    // e.stopPropagation();
    setValue("");
    onChangeCallback && debouncedCallback("");
  };

  const debouncedCallback = useCallback(
    onChangeCallback
      ? debounce((nextValue: string) => {
          onChangeCallback(nextValue);
        }, 0)
      : () => {},
    [onChangeCallback]
  );

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.target.setAttribute("autocomplete", "off");
  };

  useEffect(() => {
    if (value === initialValue) {
      return;
    }
    setValue(initialValue);
  }, [initialValue]);

  return (
    <TextInputContainer
      id={id}
      $grow={$grow}
      $borderless={$borderless}
      data-testid="text_input_container"
      nameEdit={nameEdit}
      showClear={nameEdit ? false : value !== ""}
      $noPadding={$noPadding}
      onClick={onClick}
      style={{ pointerEvents: 'auto' }}
    >
      {icon && <Icon src={icon} alt="input icon" height="12px" />}
      <Form autoComplete="off" style={{ pointerEvents: 'auto' }}>
        <Input
          autoComplete="off"
          type="search"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          $cursorPointer={disabled}
          name={`search_${Math.random()}`}
          readOnly={disabled}
          aria-disabled={disabled ? "true" : "false"}
          fontWeight={nameEdit ? "600" : "400"}
          textAlign={nameEdit ? "right" : "left"}
          onClick={(e) => {
            if (!disabled) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          style={{ pointerEvents: 'auto' }}
        />
      </Form>
      {!hideClear && (
        <Button iconLeft={Close} handleClick={handleReset} $borderless />
      )}
    </TextInputContainer>
  );
};

export default TextInput;
