import styled from "styled-components";
import sizing from "../../designSystem/sizing";
import colors from "../../designSystem/colors";

export const ScrollableCardOptionsContainer = styled.div`
  display: flex;
  & > div {
    margin-right: ${sizing.SPACING.STANDARD};
  }

  & > :last-child {
    margin-right: 0;
  }

  max-width: calc(100vw - 400px);

  overflow: scroll;

  /* Hide scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;

  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;

  cursor: grab; /* Default cursor */

  &:active {
    cursor: grabbing; /* Cursor while dragging */
  }
`;

const ButtonContainer = styled.div`
  background-color: ${colors.UI.BACKGROUND.STANDARD};
  position: absolute;
  border-radius: 50%;
  top: 75px;
`;

export const BackButtonContainer = styled(ButtonContainer)`
  left: -25px; // half of round button width
`;

export const ForwardButtonContainer = styled(ButtonContainer)`
  right: -25px; // half of round button width
`;

export const Wrapper = styled.div`
  position: relative;
  width: min-content;
`;

export const CardWrapper = styled.div``;
