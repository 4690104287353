import React from "react";
import { SideBarContainer } from "./styles";
import { SideBarProps } from "./types";
import Button from "../Button";
import colors from "../../designSystem/colors";

const SideBar: React.FC<SideBarProps> = ({
  options,
  sidebarOpen,
  selected,
}) => {
  return (
    <SideBarContainer id="dashboardtour-step21" $sidebarOpen={sidebarOpen}>
      {options &&
        options.map((option, index) => (
          <Button
            handleClick={option.handeClick}
            text={option.text}
            iconLeft={option.icon}
            key={index}
            $bold={false}
            $borderless
            $background={
              selected === option.type
                ? colors.UI.BACKGROUND.ACTIVE
                : colors.UI.BACKGROUND.STANDARD
            }
            $grow
          />
        ))}
    </SideBarContainer>
  );
};

export default SideBar;
