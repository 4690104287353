import { spacing } from "react-select/dist/declarations/src/theme";
import styled from "styled-components";
import SIZING from "../../designSystem/sizing";
import { PageContentWrapperStyleProps } from "./types";
import colors from "../../designSystem/colors";
import sizing from "../../designSystem/sizing";

export const PageContentWrapper = styled.div<PageContentWrapperStyleProps>`
  padding: ${SIZING.SPACING.STANDARD};
  display: flex;
  flex-direction: column;
  position: absolute;
  width: ${({ sidebarOpen }) =>
    sidebarOpen ? "calc(100% - 24px)" : "calc(100vw + 254px - 23px)"};
  left: ${({ sidebarOpen }) => (sidebarOpen ? "0" : "-254px")};

  transition: width 250ms ease, left 250ms ease;
`;

export const PageContentHorizontalWrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const SideBarWrapper = styled.div``;

export const SideBarContainer = styled.div`
  border: 1px solid black;
  margin-right: ${SIZING.SPACING.QUAD_LARGE};
  border: 1px solid ${colors.UI.BORDER.STANDARD};
  padding: ${sizing.SPACING.STANDARD};
  border-radius: ${sizing.RADIUS.MEDIUM};
  height: calc(100vh - 109px);
  position: sticky;
  top: 5rem;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  width: 100%;
  margin-right: ${SIZING.SPACING.QUAD_LARGE};
`;

export const HeaderWrapper = styled.div`
  margin: ${SIZING.SPACING.QUAD_LARGE} 0 ${SIZING.SPACING.STANDARD} 0;
  display: flex;
  justify-content: left;
`;

export const Header = styled.h1`
  font-size: ${SIZING.SPACING.DOUBLE_LARGE};
  margin: 0;
`;

export const Coverflow = styled(Header)`
  color: ${colors.UI.TEXT.ACTIVE};
`;

export const SubText = styled.p`
  color: ${colors.UI.TEXT.MEDIUM_DARK_GREY};
  font-size: ${SIZING.TEXT.LARGE};
  margin: 0;
`;

export const SubHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${SIZING.SPACING.QUAD_LARGE} 0 ${SIZING.SPACING.LARGE} 0;
  align-items: flex-end;
`;

export const SearchWrapper = styled.div`
  margin-bottom: ${SIZING.SPACING.STANDARD};
`;

export const FilterAll = styled.p`
  color: ${colors.UI.TEXT.ACTIVE};
  cursor: pointer;
`;

export const SubHeading = styled.h2`
  font-size: ${SIZING.TEXT.H2_LARGE};
  margin: 0;
`;

export const CardWrapper = styled.div`
  display: flex;
  justify-content: left;

  & > :first-child {
    margin-right: ${SIZING.SPACING.STANDARD};
  }
`;

export const ComparisonsWrapper = styled.div``;
