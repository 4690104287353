import styled from "styled-components";
import COLORS from "../../designSystem/colors"
import SIZING from "../../designSystem/sizing"
import { RowProps, TableContainerProps } from "./types";

export const DocumentWizardTableContainer = styled.div<TableContainerProps>`
    border: 1px solid ${COLORS.UI.BORDER.STANDARD };
    border-radius: ${SIZING.RADIUS.MEDIUM};
    width: ${({$width}) => ($width ? $width : "99.5%")};
    display: flex;
    flex-direction: column;
    overflow: hidden;
    & > :last-child {
        border-bottom: 5px;
    }
`;

export const HeaderRow = styled.div`
    display: flex;
    font-weight: 500;
    padding: ${SIZING.SPACING.STANDARD};
    border-bottom: 1px solid ${COLORS.UI.BORDER.STANDARD };

`;

export const Cell = styled.div`
    display: flex;
    align-items: center;
    flex: 1 0;
    overflow: hidden;
`;

export const Row = styled.div<RowProps>`
    display: flex;
    padding: 7px;
    border-bottom: 1px solid #eee;
    background-color: ${({$selected}) => ($selected ? COLORS.UI.BACKGROUND.SELECTED : COLORS.UI.BACKGROUND.STANDARD)};
`;

export const DocumentDescriptionContainer = styled(Cell)`
    justify-content: flex-start;
    & > :first-child {
        margin-right: ${SIZING.SPACING.SMALL}
    }
`;

export const StatusContainer = styled(Cell)`
    min-width: min-content;
    flex: 0;
    margin-right: 8px;
`;

export const StatusContainerHeader = styled(StatusContainer)`
    margin-right: 80px;
`;

export const FixedHeightRowContainer =styled.div`
    height: 190px;
    overflow-y: scroll;
`;

export const NoResultsWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
`;

export const ExpandableSection = styled.div`
  overflow: hidden;
  max-height: 0;
  padding: 0px 16px;
  transition: all 0.3s;
`;
