import React, { useState, useEffect, useRef, ReactNode, Children } from "react";

import { DocumentType } from "./Document";
import { fetchMatchedPairs, getPresignedUrl } from "../requests";
import { useCookies } from "react-cookie";
import LoadingSpinner from "./base/Spinner";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

// Import styles
import "@react-pdf-viewer/highlight/lib/styles/index.css";

import { HighlightArea } from "@react-pdf-viewer/highlight";
import PairItem from "./PairItem";
import colors from "../designSystem/colors";
import RedX from "../assets/icons/RedX.svg";
import Sparkle from "../assets/icons/Sparkle.svg";
import DownloadWhite from "../assets/icons/DownloadWhite.svg";
import { ItemWrapper } from "./ComparisonModal/styles";
import Button from "../components/Button";
import { UserType } from "../LandingPage";
import {
  FilterComparisonContainer,
  FilterContainer,
  ComparisonDisplay,
  HeadedContent,
  HeadedContentContent,
  HeadedContentContentWrapper,
  HeadedContentHeader,
  HeadedContentHeaderWrapper,
  HeadedContentSubHeader,
  HeadedContentContainer,
  QuickFilterContainer,
  EnlargedHeadedContentSubHeader,
  EnlargedHeadedContentContent,
} from "../pages/Comparison/styles";
import ComparisonFilter from "./ComparisonFilter";
import TabulatedSelection from "./TabulatedSelection";
import NumberedItem from "./NumberedItem";
import { upperFirst } from "lodash";
import TextInput from "./TextInput";
import Search from "../assets/icons/Search.svg";
import SchedulesExceptionDisclaimer from "./SchedulesExceptionDisclaimer";
import { SpinnerWrapper } from "./DocumentTable/styles";
import { ChildRef } from "./TabulatedSelection/types";
import { handleDownload } from "../utils";
import { ComparisonLoaderContainer } from "./ComparisonLoader/styles";
import ComparisonLoader from "./ComparisonLoader";

export interface ResultWithReference {
  // The generated unique identifier
  id: string;

  // The result content
  coverageName: string;

  coverageType: string;

  coverageLine: string;

  keys: any;

  // The list of highlight areas
  highlightArea: HighlightArea;

  // String of Page Ref information
  pageIndex: string;
}

export interface MatchedPair {
  id: string;

  similar: boolean;

  partial: boolean;

  coverageName?: string;

  firstVal: ResultWithReference;

  secondVal: ResultWithReference;
}

const scheduleLineOptions = [
  "form_schedule",
  "umbrella_schedule",
  "vehicle_list",
  "general_mod",
  "farm_schedule",
  "liquor_schedule",
  "inland_schedule",
  "address_list",
  "workers_comp_schedule",
  "garage_addresses",
  "driver_list",
  "contractor_property_list",
];

const ComparisonModal = ({
  modalOpen,
  originalDocument,
  document,
  downloadableDocument,
  secondDocument,
  childStates,
  setChildStates,
  selectedLine,
  selectedType,
  onDownload,
  agencyId,
  onDownloadQuoteSummary
}: {
  modalOpen: boolean;
  originalDocument: DocumentType | null;
  document: DocumentType | null;
  downloadableDocument: DocumentType | null;
  retrieveDocuments: () => void;
  secondDocument: DocumentType | null;
  childStates: boolean[];
  setChildStates: (openStates: boolean[]) => void;
  setLineOptions: (s: string[]) => void;
  selectedLine: string;
  selectedType: string;
  onDownload: (doc: DocumentType | undefined) => void;
  user: UserType | null;
  startTour: () => void;
  comparisonWalkthroughDone: boolean;
  agencyId: string;
  onDownloadQuoteSummary: (d: DocumentType | undefined) => void;
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(["user-id"]);
  const [page, setPage] = useState<string>("normal");
  const [presignedUrl, setPresignedUrl] = useState<string>("");
  const [secondPresignedUrl, setSecondPresignedUrl] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [matchedPairs, setMatchedPairs] = useState<MatchedPair[]>([]);
  const [filteredMatchedPairs, setFilteredMatchedPairs] = useState<
    MatchedPair[]
  >([]);
  const [refreshEntries, setRefreshEntries] = useState<boolean>(false);
  const [localSecondDocument, setLocalSecondDocument] =
    useState<DocumentType | null>(null);
  const [additionalFilter, setAdditionalFilter] = useState<string>("all");

  const [lines, setLines] = useState<
    { coverageLine: string; count: number; similarCount: number; partialCount: number }[]
  >([]);
  const [selectedCoverageLine, setSelectedCoverageLine] = useState<string>("");
  const [selectedCoverageType, setSelectedCoverageType] = useState<string>("");
  const [coverageTypes, setCoverageTypes] = useState<
    { coverageType: string; count: number; similarCount: number; partialCount: number }[]
  >([]);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [searchFilterdMathcedPairs, setSearchFilteredMatchedPairs] = useState<
    MatchedPair[]
  >([]);
  const childRef = useRef<ChildRef>(null);
  const [localDocument, setLocalDocument] = useState<DocumentType | null>(null);
  const [documentDeleted, setDocumentDeleted] = useState<boolean>(false);
  const [noDocumentDeleted, setNoDocumentDeleted] = useState<boolean>(false);

  useEffect(() => {
    if (!selectedCoverageLine && lines[0]) {
      setSelectedCoverageLine(lines[0].coverageLine);
      handleReset();
      setLoading(false);
    }
  }, [lines]);

  const translateResultsToReactInterface = (results: any[]): MatchedPair[] => {
    const translated = results.map((result) => {
      const similar = result.similar;
      const partial = result.partial;
      const first_val = result.first_val;
      const second_val = result.second_val;
      const coverage_name = result.coverage_name;

      return {
        id: result.matched_pair_id,
        similar: similar,
        partial: partial,
        coverageName: coverage_name,
        firstVal: {
          id: first_val.coverage_id ?? "",
          coverageName: first_val.coverage_name ?? "",
          coverageType: first_val.coverage_type ?? "",
          coverageLine: scheduleLineOptions.includes(first_val.coverage_line)
            ? "Schedules"
            : first_val.coverage_line ?? "",
          keys: first_val.keys ?? {},
          highlightArea: first_val.highlight_area
            ? {
                height: first_val.highlight_area.height ?? 0,
                width: first_val.highlight_area.width ?? 0,
                left: first_val.highlight_area.left ?? 0,
                top: first_val.highlight_area.top ?? 0,
                pageIndex: first_val.highlight_area.page_index ?? 0,
              }
            : {
                height: 0,
                width: 0,
                left: 0,
                top: 0,
                pageIndex: 0,
              },
          pageIndex: first_val.page_index ?? "",
        },
        secondVal: {
          id: second_val.coverage_id ?? "",
          coverageName: second_val.coverage_name ?? "",
          coverageType: second_val.coverage_type ?? "",
          coverageLine: scheduleLineOptions.includes(second_val.coverage_line)
            ? "Schedules"
            : second_val.coverage_line ?? "",
          keys: second_val.keys ?? {},
          highlightArea: second_val.highlight_area
            ? {
                height: second_val.highlight_area.height ?? 0,
                width: second_val.highlight_area.width ?? 0,
                left: second_val.highlight_area.left ?? 0,
                top: second_val.highlight_area.top ?? 0,
                pageIndex: second_val.highlight_area.page_index ?? 0,
              }
            : {
                height: 0,
                width: 0,
                left: 0,
                top: 0,
                pageIndex: 0,
              },
          pageIndex: second_val.page_index ?? "",
        },
      };
    });
    // Handle Empty Line to Key Information
    return translated.map((res) => {
      return res.firstVal.coverageLine === "" &&
        res.secondVal.coverageLine === ""
        ? {
            ...res,
            firstVal: { ...res.firstVal, coverageLine: "Key Information" },
          }
        : res;
    });
  };

  // for manual search handling
  useEffect(() => {
    setSearchFilteredMatchedPairs(
      filteredMatchedPairs.filter(
        (p) =>
          p.firstVal.coverageName
            .toLowerCase()
            .includes(searchFilter.toLowerCase()) ||
          p.secondVal.coverageName
            .toLowerCase()
            .includes(searchFilter.toLowerCase())
      )
    );
  }, [searchFilter]);

  useEffect(() => {
    async function retrieveDocumentResults() {
      try {
        if (!document?.instanceId) {
          return;
        }
        if (!secondDocument?.instanceId) {
          return;
        }
        if (matchedPairs.length == 0) setLoading(true);
        let resp = await fetchMatchedPairs(cookies["user-id"], [
          document?.instanceId ?? "",
          secondDocument?.instanceId ?? "",
        ]);
        let results = [...resp.body["similar"], ...resp.body["diff"]];
        const translatedResults = translateResultsToReactInterface(results);
        if (translatedResults.length > 0) {
          setNoDocumentDeleted(true);
        }
        setMatchedPairs(translatedResults);
        generateTabulatedOptions(translatedResults);

        const lineStats = {};
        translatedResults.forEach((res) => {
          const coverageLine =
            res.firstVal.coverageLine || res.secondVal.coverageLine;
          if (!coverageLine || coverageLine === "Schedules") {
            return;
          }

          // Initialize line stats if not already present
          if (!lineStats[coverageLine]) {
            lineStats[coverageLine] = {
              coverageLine,
              count: 0,
              similarCount: 0,
              partialCount: 0
            };
          }

          // Increment count for this line
          lineStats[coverageLine].count += 1;

          // Increment similarCount if res.similar is true
          if (res.similar) {
            lineStats[coverageLine].similarCount += 1;
          }
          if (res.partial) {
            lineStats[coverageLine].partialCount += 1;
          }
        });

        const newLines = Object.values(lineStats).sort(
          // @ts-ignore
          (a, b) => b.count - b.similarCount - (a.count - a.similarCount)
        );

        //@ts-ignore
        setLines(newLines);

        //setLoading(false);
      } catch (error) {
        console.error("Failed to retrieve matched  pairs:", error);
      }
    }

    retrieveDocumentResults(); // Initial call

    if (refreshEntries) {
      setRefreshEntries(false);
    }

    return () => {};
  }, [document, secondDocument, refreshEntries]);

  useEffect(() => {
    let filtered = matchedPairs
      .filter((p) => {
        return (
          p.firstVal.coverageLine === selectedCoverageLine ||
          (p.firstVal.coverageLine === "" &&
            p.secondVal.coverageLine === selectedCoverageLine)
        );
      })
      .filter(
        (p) =>
          p.firstVal.coverageType === selectedCoverageType ||
          p.secondVal.coverageType === selectedCoverageType
      );

    if (additionalFilter == "partial")
        filtered = filtered.filter((p) => p.partial);
    else if (additionalFilter == "different")
      filtered = filtered.filter((p) => !p.similar);
    else if (additionalFilter == "similar")
      filtered = filtered.filter((p) => p.similar);


    if (childStates.length === 0) {
      const initialOpenStates = new Array(filtered.length).fill(false);
      setChildStates(initialOpenStates);
    }
    setFilteredMatchedPairs(filtered.sort((a, b) => a.coverageName?.localeCompare(b.coverageName ?? "") ?? 0));
    setSearchFilter("");
  }, [
    matchedPairs,
    selectedLine,
    selectedType,
    additionalFilter,
    selectedCoverageLine,
    selectedCoverageType,
  ]);

  useEffect(() => {
    generateTabulatedOptions(matchedPairs);
  }, [selectedCoverageLine]);

  useEffect(() => {
    let intervalId;

    async function retrievePresignedUrls() {
      if (modalOpen) {
        try {
          if (presignedUrl && secondPresignedUrl) return;
          const resp = await getPresignedUrl(
            cookies["user-id"],
            localDocument?.link ?? "",
            localDocument?.instanceId ?? "",
            false,
            false
          );
          const resp2 = await getPresignedUrl(
            cookies["user-id"],
            localSecondDocument?.link ?? "",
            localSecondDocument?.instanceId ?? "",
            false,
            false
          );
          if (resp.status == 404 || resp2.status == 404) {
            if (originalDocument && (!localDocument || !localSecondDocument)) {
              setDocumentDeleted(true);
              await new Promise(r => setTimeout(r, 1000));
              setLoading(false);
            } else {
              setNoDocumentDeleted(true);
            }
          } else {
            setNoDocumentDeleted(true);
            setDocumentDeleted(false);
            setPresignedUrl(resp.body["url"]);
            setSecondPresignedUrl(resp2.body["url"]);
          }
        } catch (error) {
          console.error("Failed to retrieve presigned URL:", error);
        }
      }
    }

    retrievePresignedUrls(); // Initial call
    if (modalOpen) {
      intervalId = setInterval(retrievePresignedUrls, 59 * 60 * 1000); // Set interval for 59 minutes
    }

    return () => {
      clearInterval(intervalId); // Clear interval on component unmount or dependencies change
    };
  }, [
    localDocument,
    localDocument?.instanceId,
    localDocument?.category,
    localSecondDocument,
    originalDocument,
    modalOpen,
  ]);

  const handleToggle = (value: boolean, key: number) => {
    const updatedStates = childStates.map((state, index) =>
      index === key ? value : state
    );
    setChildStates(updatedStates);
  };

  const generateTabulatedOptions = (translatedResults: MatchedPair[]) => {
    const typeStats = {};
    translatedResults
      .filter(
        (p) =>
          p.firstVal.coverageLine === selectedCoverageLine ||
          (p.firstVal.coverageLine === "" &&
            p.secondVal.coverageLine === selectedCoverageLine)
      )
      .forEach((res) => {
        const coverageType =
          res.firstVal.coverageType || res.secondVal.coverageType;

        if (!typeStats[coverageType]) {
          typeStats[coverageType] = {
            coverageType,
            count: 0,
            similarCount: 0,
          };
        }

        typeStats[coverageType].count += 1;

        if (res.similar) {
          typeStats[coverageType].similarCount += 1;
        }
      });

    const newTypes = Object.values(typeStats).sort(
      // @ts-ignore
      (a, b) => b.count - b.similarCount - (a.count - a.similarCount)
    );

    //@ts-ignore
    setCoverageTypes(newTypes);

    //@ts-ignore
    newTypes.length > 0 && setSelectedCoverageType(newTypes[0].coverageType);
  };

  const generateOptionsFromCoverageTypes = () => {
    const schedules = matchedPairs.filter(
      (p) =>
        p.firstVal.coverageType.toLowerCase() === "schedule" ||
        p.secondVal.coverageType.toLowerCase() === "schedule"
    );
    const types = coverageTypes;

    if (
      schedules.length > 0 &&
      !types.find((e) => e.coverageType === "Schedule") &&
      selectedCoverageLine !== "Key Information"
    ) {
      //@ts-ignore
      types.push({
        coverageType: "Schedule",
        count: schedules.length,
        similarCount: schedules.filter((p) => p.similar).length,
      });
    }

    return types.map((numberedItem) => {
      return (
        <NumberedItem
          itemLabel={
            numberedItem.coverageType.toLowerCase() === "generic"
              ? "Key Information"
              : upperFirst(numberedItem.coverageType)
          }
          number={numberedItem.count - numberedItem.similarCount}
        />
      );
    });
  };

  const handleLineFilter = (selection: string) => {
    selection && setSelectedCoverageLine(selection);
    handleReset();
  };

  const handleCoverageTypeSelection = (index: number) => {
    setChildStates(new Array(matchedPairs.length).fill(false));
    setSelectedCoverageType(coverageTypes[index].coverageType);
  };

  useEffect(() => {
    if (coverageTypes.length === 0) {
      return;
    }
    setSelectedCoverageType(coverageTypes[0].coverageType);
  }, [coverageTypes]);

  // handling of reseting type filter when changing line filter
  const handleReset = () => {
    if (childRef.current) {
      childRef.current.resetIndex();
    }

    setChildStates(new Array(matchedPairs.length).fill(false));
  };

  useEffect(() => {
    if (
      document?.instanceId &&
      document.instanceId.localeCompare(secondDocument?.instanceId ?? "") < 0
    ) {
      setLocalDocument(document);
      setLocalSecondDocument(secondDocument);
    } else {
      setLocalDocument(secondDocument);
      setLocalSecondDocument(document);
    }
  }, [document, secondDocument]);

  const renderItems = (arr: MatchedPair[]) => {
    return arr
      .sort((a, b) => {
        const rank = (obj) => obj.similar ? 2 : obj.partial ? 1 : 0;
        return rank(a) - rank(b);
      })
      .map((pair, i) => {
        return (
          <ItemWrapper id={i == 0 ? "comparisontour-step3" : undefined}>
            <PairItem
              key={pair.id}
              matchedPairId={pair.id}
              coverageName={pair.coverageName}
              firstResult={pair.firstVal}
              secondResult={pair.secondVal}
              firstName={document?.name || ""}
              secondName={secondDocument?.name ?? ""}
              firstPresignedUrl={presignedUrl}
              secondPresignedUrl={secondPresignedUrl}
              similar={pair.similar}
              partial={pair.partial}
              setRefresh={setRefreshEntries}
              matchedPairs={matchedPairs}
              setMatchedPairs={setMatchedPairs}
              $backgroundColor={
                pair.similar
                  ? colors.UI.BACKGROUND.SELECTED
                  : pair.partial
                    ? colors.UI.BACKGROUND.PARTIAL
                    : colors.UI.BACKGROUND.DANGER
              }
              $borderColor={
                pair.similar
                  ? colors.UI.BORDER.SELECTED
                  : pair.partial
                    ? colors.UI.BORDER.PARTIAL
                    : colors.UI.BORDER.DANGER
              }
              iconLeft={pair.similar ? Sparkle : RedX}
              onOpen={childStates[i]}
              toggle={handleToggle}
              index={i}
            />
          </ItemWrapper>
        );
      });
  };

  return (
    <>
      { (documentDeleted && !noDocumentDeleted && !loading && document?.status != "analyzing") && (
        <ComparisonLoader
          document={originalDocument}
          onDownload={onDownload}
          onDownloadQuoteSummary={onDownloadQuoteSummary}
          agencyId={agencyId}
          subtitle="One of the documents was deleted, but you can still download the excel above."
        />
      )}
      { loading ? (
        <>
          <SpinnerWrapper>
            <LoadingSpinner />
          </SpinnerWrapper>
        </>
      //) : ((selectedCoverageLine || noDocumentDeleted) ? (
      ) : (selectedCoverageLine ? (
        <>
          <HeadedContentContainer>
            <HeadedContent>
              <HeadedContentHeaderWrapper>
                <HeadedContentHeader>General Overview</HeadedContentHeader>
              </HeadedContentHeaderWrapper>
              <HeadedContentContentWrapper>
                <EnlargedHeadedContentSubHeader>
                  Score
                </EnlargedHeadedContentSubHeader>
                <EnlargedHeadedContentContent>
                  {matchedPairs.filter((p) => p.similar).length}/
                  {matchedPairs.length} data points matching ({matchedPairs.filter((p) => p.partial).length} partial matches)
                </EnlargedHeadedContentContent>
              </HeadedContentContentWrapper>
            </HeadedContent>
          </HeadedContentContainer>
          <FilterComparisonContainer>
            <FilterContainer>
              <ComparisonFilter
                handleSelection={handleLineFilter}
                lines={lines.map((line) => ({
                  label: line.coverageLine,
                  count: line.count - line.similarCount,
                }))}
              />
            </FilterContainer>

            <ComparisonDisplay>
              <HeadedContent>
                <HeadedContentHeaderWrapper>
                  <HeadedContentHeader>
                    {selectedCoverageLine}
                  </HeadedContentHeader>
                </HeadedContentHeaderWrapper>
                <HeadedContentContentWrapper>
                  <HeadedContentSubHeader>Score</HeadedContentSubHeader>
                  <HeadedContentContent>
                    {
                      lines.find((e) => e.coverageLine === selectedCoverageLine)
                        ?.similarCount
                    }
                    /
                    {
                      lines.find((e) => e.coverageLine === selectedCoverageLine)
                        ?.count
                    }{" "}
                    data points matching ({lines.find((e) => e.coverageLine === selectedCoverageLine)?.partialCount} partial matches)
                  </HeadedContentContent>
                </HeadedContentContentWrapper>
                <HeadedContentContentWrapper>
                  <TabulatedSelection
                    options={generateOptionsFromCoverageTypes()}
                    handleSelection={handleCoverageTypeSelection}
                    ref={childRef}
                  />
                </HeadedContentContentWrapper>
                {selectedCoverageType.toLocaleLowerCase() === "schedule" && (
                  <SchedulesExceptionDisclaimer
                    handleClick={() => {
                      downloadableDocument && onDownload(downloadableDocument);
                    }}
                    generating={downloadableDocument?.status != "analyzed" && downloadableDocument?.status != "generated"}
                  />
                )}
                {selectedCoverageType.toLocaleLowerCase() !== "schedule" && (
                  <>
                    <HeadedContentContentWrapper>
                      <QuickFilterContainer>
                        <TextInput
                          id="dashboardtour-step22"
                          icon={Search}
                          placeholder="Search"
                          initialValue={searchFilter}
                          $grow
                          onChangeCallback={(e) => {
                            setSearchFilter(e);
                          }}
                        />
                        <Button
                          handleClick={() =>
                            setAdditionalFilter((prev) => {
                              if (prev == "different") return "all";
                              return "different";
                            })
                          }
                          iconLeft={RedX}
                          text="Show differences"
                          $borderColor={colors.UI.BORDER.DANGER}
                          $bold={false}
                          $background={
                            additionalFilter == "different"
                              ? colors.UI.BACKGROUND.DANGER_DARK
                              : colors.UI.BACKGROUND.DANGER
                          }
                          $height={"auto"}
                        />
                        <Button
                          handleClick={() =>
                            setAdditionalFilter((prev) => {
                              if (prev == "similar") return "all";
                              return "similar";
                            })
                          }
                          iconLeft={Sparkle}
                          text="Show matches"
                          $borderColor={colors.UI.BORDER.SELECTED}
                          $bold={false}
                          $background={
                            additionalFilter == "similar"
                              ? colors.UI.BACKGROUND.SELECTED_DARK
                              : colors.UI.BACKGROUND.SELECTED
                          }
                          $height={"auto"}
                        />
                      </QuickFilterContainer>
                    </HeadedContentContentWrapper>
                    <HeadedContentContentWrapper>
                      {renderItems(
                        searchFilter
                          ? searchFilterdMathcedPairs
                          : filteredMatchedPairs
                      )}
                    </HeadedContentContentWrapper>
                  </>
                )}
              </HeadedContent>
            </ComparisonDisplay>
          </FilterComparisonContainer>
        </>
      ) : (!documentDeleted && (
        <ComparisonLoader
          document={originalDocument}
          onDownload={onDownload}
          onDownloadQuoteSummary={onDownloadQuoteSummary}
          agencyId={agencyId}
          subtitle="This comparison doesn't have enough data to show in-app, but you can download the spreadsheet above."
        />
      )))}
    </>
  );
};

export default ComparisonModal;
