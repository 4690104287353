import { styled } from "styled-components";
import { PillProps } from "./types";
import SIZING from "../../designSystem/sizing";
import { getPillStyles } from "./utils";
import COLORS from "../../designSystem/colors";

export const PillContainer = styled.div<PillProps>`
  display: flex;
  background-color: ${({ type, $greyscale }) =>
    $greyscale
      ? COLORS.UI.BACKGROUND.STANDARD
      : getPillStyles(type).BACKGROUND};
  border: 1px solid
    ${({ type, $greyscale }) =>
      $greyscale ? COLORS.UI.BORDER.STANDARD : getPillStyles(type).BORDER};
  border-radius: ${SIZING.RADIUS.MEDIUM};
  height: min-content;
  padding: ${SIZING.SPACING.SMALL} ${SIZING.SPACING.STANDARD};
  width: ${({ $grow }) => ($grow ? "100%" : "min-content")};
  vertical-align: middle;
  justify-content: space-around;

  ${({ onClick }) =>
    onClick &&
    `
        cursor: pointer;

        &:hover {
            filter: brightness(0.9); /* Darkens the entire element */
        }
    `}
`;

export const IconContainer = styled.img`
  margin-right: ${SIZING.SPACING.SMALL};
  max-height: 15px;
  max-width: 20px;
`;

export const IconTextWrapper = styled.div`
  display: flex;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Text = styled.span<PillProps>`
  font-weight: 500;
  color: ${({ type, $greyscale }) =>
    $greyscale ? COLORS.UI.TEXT.STANDARD : getPillStyles(type).TEXT};
  text-wrap: ${({ $nowrap }) => ($nowrap ? "nowrap" : "wrap")};
  display: flex;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const PeriodsWrapper = styled.span`
  opacity: 0;
`;

export const PeriodsDisplay = styled.span`
  position: absolute;
  left: 0;
  color: unset;
`;

export const PeriodsContainer = styled.div`
  position: relative;
`;
