import React, { useEffect, useRef, useState } from 'react';

import { Header, SubHeader } from '../../Header/styles';
import Search from '../../../assets/icons/Search.svg';
import Upload from "../../../assets/icons/Upload.svg";
import DownCarat from "../../../assets/icons/DownCarat.svg";
import TextInput from '../../TextInput';
import Text from '../../Text';
import { SelectFirstPolicyProps } from '../types';
import { ButtonWrapper, ComparisonWrapper, HeaderWrapper, MyFilesToggle, ProgressDisplay, SearchWrapper, SelectedDocumentsWrapper, SelectionWrapper } from '../styles';
import DocumentWizardTable from '../../DocumentWizardTable';
import Button from '../../Button';
import Close from '../../../assets/icons/x-close.svg'
import CloseActive from '../../../assets/icons/Close_Active.svg'
import FileActive from '../../../assets/icons/File_active.svg'
import COLORS from '../../../designSystem/colors';
import { DocumentType } from '../../Document';
import { InitialWrapper, TableContainer } from '../../DocumentTable/styles';
import { HiddenInput } from '../../Navbar/styles';
import Space from '../../base/Space';

const SelectFirstPolicy: React.FC<SelectFirstPolicyProps> = ({ rows, headers, handleSelect, handleFileChange, handleContinue, handleCancel, progressBlocked, selected, handleRemoveFromSelected, successfulUploads }) => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [filteredDocuments, setFilteredDocuments] = useState<DocumentType[]>([]);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [showMyFiles, setShowMyFiles] = useState<boolean>(false);

    const handleSearch = (term: string) => setSearchTerm(term);

    useEffect(() => {
        if (rows) {
          const filteredList = rows.filter(document => document.name.toLowerCase().includes(searchTerm.toLowerCase()));
            setFilteredDocuments(filteredList);
          }
      }, [searchTerm, rows]);

    // <Text content='You can choose as many documents associated with this policy.'/>


    const handleDragOver = (event: React.DragEvent) => {
        event.preventDefault();
        setIsDragging(true);
        };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = async (event: React.DragEvent) => {
        event.preventDefault();
        setIsDragging(false);

        handleFileChange(event);
    };


    const fileInputRef = useRef<HTMLInputElement>(null);
    
    const handleUpload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    useEffect(() => {
        if (successfulUploads) {
            for (let i = 0; i < successfulUploads.length; i++) {
                const lastUploaded = rows.find(r => r.instanceId == successfulUploads[i]);
                if (lastUploaded && !selected.find(s => s.instanceId == successfulUploads[i])) 
                    handleSelect([...selected, lastUploaded]);
            }
        }
    }, [successfulUploads])

    return(
        <>
            <HeaderWrapper>
                <ProgressDisplay>
                    <Text content="SELECT POLICIES" />
                </ProgressDisplay>
                <Button iconLeft={Close} handleClick={handleCancel} $borderless/>
            </HeaderWrapper>
            <SubHeader>Select Policies to Compare</SubHeader>
            <TableContainer
                isDragging={isDragging}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                $height={ showMyFiles ? "126px" : "246px" }
                $width="100%"
                $marginTop="20px"
                $dashedBorder
            >
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "16px", height: "100%", width: "100%"}}>
                    <div style={{ fontSize: "20px", fontWeight: "500" }}>Drag and drop your files</div>
                    <div style={{ fontSize: "16px", fontWeight: "400" }}>or</div>
                    <Button
                        iconLeft={Upload}
                        text="Upload"
                        handleClick={handleUpload}
                    />
                    <HiddenInput
                        type="file"
                        ref={fileInputRef}
                        multiple
                        onChange={(e) => handleFileChange(e)}
                    />
                </div>
            </TableContainer>
            <Space px={16} />
            <div style={{ display: "flex", flexDirection: "column"}}>
                <DocumentWizardTable rows={filteredDocuments} chosen={selected} allRows={rows} onSelect={handleSelect} handleSearch={handleSearch} showMyFiles={showMyFiles} setShowMyFiles={setShowMyFiles}/>
            </div>

            {selected[0] && (
                <SelectedDocumentsWrapper>
                    {selected.map((document, index) => {
                        return (
                            <Button
                                key={index}
                                handleClick={() => handleRemoveFromSelected(document)}
                                iconLeft={FileActive}
                                text={document.name.length < 60 ? document.name : document.name.slice(0,57) + "..."}
                                iconRight={CloseActive}
                                $color={COLORS.UI.BORDER.SELECTED}
                                $background={COLORS.UI.BACKGROUND.SELECTED} />
                        );
                    })}    
                </SelectedDocumentsWrapper>
            )}

            <div style={{ margin: "10px" }}>
                { selected.length > 2 && 
                    "Due to constraints of AI models available currently, 3+-way comparisons will contain less information than 2-way comaprisons."
                }
            </div>


            <ButtonWrapper>
                <Button text="Cancel" handleClick={handleCancel}/>
                <Button disabled={progressBlocked} text="Continue" handleClick={handleContinue} $secondary/>
            </ButtonWrapper>
        </>
    );
}

export default SelectFirstPolicy;