import React, { useRef } from "react";
import { FileCardProps } from "./types";
import {
  ColoredTextWrapper,
  FileCardContainer,
  Header,
  IndicatorContainer,
  PillContainer,
  SpinnerWrapper,
} from "./styles";
import LoadingSpinner from "../base/Spinner";
import { Icon } from "../Icon/styles";
import ColoredText from "../ColoredText";
import { getColorFromCount } from "../ComparisonFilter/utils";

const FileCard: React.FC<FileCardProps> = ({
  fileIcon,
  header,
  fileNames,
  handleClick,
  differencesCount,
  loading
}) => {
  const containerRef = useRef(null);

  return (
    <FileCardContainer onClick={handleClick}>
      <IndicatorContainer>
        <Icon alt="File Icon" height="64px" src={fileIcon} />
        {!loading ? ((differencesCount !== undefined && differencesCount >= 0) && (
          <ColoredTextWrapper>
            <ColoredText
              backgroundColor={getColorFromCount(
                differencesCount,
                "background"
              )}
              color={getColorFromCount(differencesCount, "text")}
            >
              {differencesCount} items
            </ColoredText>
          </ColoredTextWrapper>
        )) : (
          <SpinnerWrapper>
            <LoadingSpinner />
          </SpinnerWrapper>
        )}
      </IndicatorContainer>
      <Header>{header}</Header>
      <>
        <PillContainer ref={containerRef}>{fileNames}</PillContainer>
      </>
    </FileCardContainer>
  );
};

export default FileCard;
