import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { SelectInputProps, MenuItemProps } from './types';
import { SelectContainer, StyledLabel, LabelText, StyledIcon, SearchWrapper, NoneFoundWrapper, MenuSearchFail } from './styles';
import { BasicMenu, BasicMenuItem } from '../BaseMenu/styles';
import TextInput from '../TextInput';
import Button from '../Button';
import Plus from "../../assets/icons/Plus.svg";
import Search from "../../assets/icons/Search.svg";
import Check from "../../assets/icons/Check.svg";
import { Icon } from '../Icon/styles';
import COLORS from '../../designSystem/colors';
import EditableText from '../EditableText';
import { changeClientName } from '../../requests';
import { useCookies } from 'react-cookie';

const MenuItem: React.FC<MenuItemProps> = ({ option, handleSelect, selected }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [localName, setLocalName] = useState<string>(option.label);

  useEffect(() => {
    setLocalName(option.label);

  }, [option])

  const onChangeName = (s: string) => {
    setLocalName(s);
    changeClientName(cookies['user-id'], option.value, s);
  }

  return (
    <BasicMenuItem
      key={option.value}
      role="option"
      aria-selected={option.value === selected}
      onClick={() => handleSelect(option.value)}
    >
      <EditableText defaultValue={localName} onSubmitChange={onChangeName} onClick={() => handleSelect(option.value)} $noPadding />
      {/*option.value === selected && <Icon src={Check} alt="selected icon"/>*/}
    </BasicMenuItem>
  )
}

const SelectInput: React.FC<SelectInputProps> = ({ id, options, initial, icon, callback, searchCallback, $grow, $minWidth, addClient }) => {
    const [selected, setSelected] = useState<string | undefined>(initial?.value);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      setSelected(initial?.value);
    }, [initial?.value])

    const toggleMenu = () => {
      searchCallback && searchCallback("");
      setIsMenuOpen((prev) => !prev);
    };

    const handleSelect = (value: string) => {
        setSelected(value);
        setIsMenuOpen(false);
        callback && callback(value);
    };

    const handleSearch = (searchValue) => {
      searchCallback && searchCallback(searchValue)
    }

    const handleAddClient = () => {
      addClient && addClient(true);
    }

    const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
      if (!menuRef.current?.contains(e.relatedTarget as Node)) {
          // setIsMenuOpen(false);
      }
  };

    const renderMenu = (): ReactNode => {
        return (
          <BasicMenu role="listbox" ref={menuRef}>
            <SearchWrapper>
              <TextInput onChangeCallback={handleSearch} icon={Search} placeholder='Search...' $grow/>
            </SearchWrapper>
            <Button text='Add Client' iconLeft={Plus} handleClick={handleAddClient} $bold={false} $borderless $color={COLORS.UI.TEXT.ACTIVE} />
            {options && options.length > 0 && options.map((option, index) => (
              <MenuItem key={option.value} index={index} option={option} handleSelect={handleSelect} selected={selected} />
            ))}
            {options.length === 0 && <NoneFoundWrapper><MenuSearchFail>No Clients Found</MenuSearchFail></NoneFoundWrapper>}
          </BasicMenu>
        );
      };
    
    return(
        <SelectContainer
          id={id ?? undefined}
          $grow={$grow}
          $minWidth={$minWidth}
          onBlur={handleBlur}
          tabIndex={0}
          $isMenuOpen={isMenuOpen}>
            {selected && <StyledLabel onClick={toggleMenu} role="textbox">
                <LabelText>{initial?.label}</LabelText>
                {icon && <StyledIcon src={icon} alt="Select Input Icon" />}
            </StyledLabel>
            }
            {isMenuOpen && renderMenu()}
        </SelectContainer>
    )
}

export default SelectInput;