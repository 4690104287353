import styled from "styled-components";
import COLORS from '../../designSystem/colors'
import SIZING from '../../designSystem/sizing'

export const BasicMenu = styled.div`
    position: absolute;
    top: calc(100% + 4px);
    width: 100%;
    min-width: 300px;
    max-height: 80vh;
    overflow-y: scroll;
    background: ${COLORS.UI.BACKGROUND.STANDARD};
    border: 1px solid ${COLORS.UI.BORDER.STANDARD};
    border-radius: ${SIZING.RADIUS.MEDIUM};
    z-index: 1000;
    box-shadow: 0px 4px 6px -1px #0000001A;
    display: flex;
    flex-direction: column;
`;

export const BasicMenuItem = styled.div`
    background: ${COLORS.UI.BACKGROUND.STANDARD};    
    border: 1px solid ${COLORS.UI.BACKGROUND.STANDARD};
    margin: ${SIZING.SPACING.SMALLEST};
    white-space: nowrap;
    flex-grow: 1;

    cursor: pointer;
    border-radius: ${SIZING.RADIUS.MEDIUM};
    /*padding: ${SIZING.SPACING.SMALL} ${SIZING.SPACING.SMALL} ${SIZING.SPACING.SMALL} ${SIZING.SPACING.STANDARD};*/
    padding: 0px ${SIZING.SPACING.SMALL} 0px 0px;

    display: flex;
    justify-content: space-between;
    // width: 100%;
    align-items: center;

    &[aria-selected='true'] {
        background-color: ${COLORS.UI.BACKGROUND.ACTIVE};
        border: 1px solid ${COLORS.UI.BACKGROUND.ACTIVE};
        color: ${COLORS.UI.TEXT.ACTIVE};
        font-weight: 500;
    }
        
    &:hover {
        background-color: ${COLORS.UI.BUTTON.PRIMARY.HOVER};
    }
`;