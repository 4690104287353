import { useEffect, useState } from "react";
import introJs from "intro.js";
import { useNavigate } from "react-router-dom";

import { ComparisonPageProps } from "./types";
import AppHeader from "../../components/Navbar";
import {
  ComparisonPageContainer,
  ComparisonsContainer,
  ComparisonsWrapper,
} from "./styles";
import TopBar from "../../components/TopBar";
import { Line } from "../../components/TopBar/types";
import ComparisonModal from "../../components/ComparisonModal";
import { DocumentType } from "../../components/Document";
import {
  deleteDocument,
  markWalkthroughCompleted,
  retrieveDocClient,
} from "../../requests";
import { Pages } from "../../LandingPage/types";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { comparisonWT } from "../../walkthroughs/comparison";
import ComparisonLoader from "../../components/ComparisonLoader";

const ComparisonPage: React.FC<ComparisonPageProps> = ({
  user,
  clientId,
  setClientId,
  clients,
  setCreateClientModalOpen,
  setSettingsModalOpen,
  onHome,
  handleFileChange,
  retrieveDocuments,
  onDownload,
  onDownloadQuoteSummary,
  documents,
  setDeleting,
  setShowDeletingToast,
  retrieveUserInfo,
  agencyId
}) => {
  const { comparisonId } = useParams();
  const [cookies, setCookie, removeCookie] = useCookies(["user-id"]);
  const [selectedLine, setSelectedLine] = useState<Line>("All Lines");
  const [selectedType, setSelectedType] = useState<string>("All Types");
  const [lineOptions, setLineOptions] = useState<string[]>([]);
  const [childStates, setChildStates] = useState<boolean[]>([]);
  const [localDocument, setLocalDocument] = useState<DocumentType | null>(null);
  const [document, setDocument] = useState<DocumentType | null>(null);
  const [secondDocument, setSecondDocument] = useState<DocumentType | null>(
    null
  );
  const [comparisonNotFound, setComparisonNotFound] = useState<boolean>(false);
  const [comparisonWalkthroughDone, setComparisonWalkthroughDone] =
    useState<boolean>(false);
  const startTour = async () => {
    const intro = introJs();

    intro.setOptions({
      steps: comparisonWT,
      showStepNumbers: true,
      exitOnOverlayClick: false,
    });

    async function onWTComplete() {
      await markWalkthroughCompleted(cookies["user-id"], "comparison");
      setComparisonWalkthroughDone(true);
      retrieveUserInfo();
    }

    intro.oncomplete(() => {
      onWTComplete();
    });

    intro.onexit(() => {
      onWTComplete();
    });

    intro.start();
  };

  useEffect(() => {
    async function getClientId() {
      if (comparisonId) {
        const resp = await retrieveDocClient(cookies['user-id'], comparisonId, "report_run");
        if (resp.status == 200 && resp.body["client_id"]) {
          setClientId(resp.body["client_id"]);
          localStorage.setItem("selectedClientId", resp.body["client_id"]);
        }
      }
    }

    getClientId();

  }, [comparisonId]);

  useEffect(() => {
    const comparisonDocument =
      documents.find((d) => d.instanceId == comparisonId) ?? null;
    if (comparisonDocument) {
      setComparisonNotFound(false);
      setLocalDocument(comparisonDocument);
      setDocument(
        documents.find(
          (d) =>
            comparisonDocument.instanceIds &&
            comparisonDocument.instanceIds.length > 0 &&
            d.instanceId == comparisonDocument.instanceIds[0]
        ) ?? null
      );
      setSecondDocument(
        documents.find(
          (d) =>
            comparisonDocument.instanceIds &&
            comparisonDocument.instanceIds.length > 1 &&
            d.instanceId == comparisonDocument.instanceIds[1]
        ) ?? null
      );
    } else if (documents.length > 0) {
      setComparisonNotFound(true);
    }
  }, [comparisonId, documents]);

  const handleCollapseAll = () => {
    setChildStates((prev) => prev.map(() => false));
  };

  const handleExpandAll = () => {
    setChildStates((prev) => prev.map(() => true));
  };

  const handleDelete = async (row: DocumentType | null) => {
    setDeleting(true);
    setShowDeletingToast(true);
    await deleteDocument(
      cookies["user-id"],
      row?.instanceId || "",
      row?.category == "analysis" || row?.category == "comparison",
      row?.category == "proposal"
    );
    setDeleting(false);
    retrieveDocuments && retrieveDocuments();
    onHome();
  };

  //TODO implement
  const regenerateComparison = () => {
    console.log("Regenerate Comparison");
  };

  const handleChildStateChange = (bools: boolean[]) => {
    setChildStates(bools);
  };

  return (
    <ComparisonPageContainer>
      <AppHeader
        clientId={clientId}
        setClientId={setClientId}
        clients={clients}
        setCreateClientModalOpen={setCreateClientModalOpen}
        setSettingsModalOpen={setSettingsModalOpen}
        onHome={onHome}
        handleFileChange={handleFileChange}
        handleRegenerateComparison={regenerateComparison}
        document={localDocument ?? undefined}
        retrieveDocuments={retrieveDocuments}
        page={Pages.COMPARISON}
        handleDelete={handleDelete}
      />
      <TopBar
        document={localDocument ?? undefined}
        onBack={onHome}
        handleCollapseAll={handleCollapseAll}
        handleExpandAll={handleExpandAll}
        download={onDownload}
        downloadQuoteSummary={(localDocument?.instanceIds && localDocument?.instanceIds.length < 4)
          ? onDownloadQuoteSummary
          : undefined
        }
        deleteDoc={() => handleDelete(localDocument)}
        lines={lineOptions}
        types={["Key Information", "Limits", "Endorsements", "Schedules"]}
        selectedLine={selectedLine}
        setSelectedLine={setSelectedLine}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        page={Pages.COMPARISON}
        agencyId={agencyId}
      />
      {comparisonNotFound && (
        <div
          style={{
            fontSize: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "70vh",
          }}
        >
          404: Comparison Not Found
        </div>
      )}
      {((!comparisonNotFound && localDocument && !localDocument.websiteReady) ||
        (localDocument?.instanceIds &&
          localDocument.instanceIds.length > 2)) && (
        <ComparisonsContainer>
          <ComparisonsWrapper>
            <ComparisonLoader
              document={localDocument}
              onDownload={onDownload}
              onDownloadQuoteSummary={onDownloadQuoteSummary}
              agencyId={agencyId}
            />
          </ComparisonsWrapper>
        </ComparisonsContainer>
      )}
      {!comparisonNotFound &&
        localDocument?.websiteReady &&
        localDocument?.instanceIds &&
        localDocument.instanceIds.length == 2 && (
          <ComparisonsContainer>
            <ComparisonsWrapper>
              <ComparisonModal
                modalOpen={true}
                originalDocument={localDocument}
                document={document}
                downloadableDocument={localDocument}
                retrieveDocuments={retrieveDocuments}
                secondDocument={secondDocument}
                childStates={childStates}
                setChildStates={handleChildStateChange}
                setLineOptions={setLineOptions}
                selectedLine={selectedLine}
                selectedType={selectedType}
                onDownload={onDownload}
                user={user}
                startTour={startTour}
                comparisonWalkthroughDone={comparisonWalkthroughDone}
                agencyId={agencyId}
                onDownloadQuoteSummary={onDownloadQuoteSummary}
              />
            </ComparisonsWrapper>
          </ComparisonsContainer>
        )}
    </ComparisonPageContainer>
  );
};

export default ComparisonPage;
