import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import { DashboardProps } from "./types";
import { PillType } from "../../components/Pill/types";
import Modal from "../../components/Modal";
import CreateProposal from "../../components/CreateProposal";
import DocumentTable, { LinkedDocuments } from "../../components/DocumentTable";
import AppHeader from "../../components/Navbar";
import PolicyComparisonWizard from "../../components/PolicyComparisonWizard";
import SideBar from "../../components/SideBar";
import {
  PageContentWrapper,
  PageContentHorizontalWrapper,
  ContentWrapper,
  Header,
  Coverflow,
  SubText,
  SubHeading,
  HeaderWrapper,
  CardWrapper,
  ComparisonsWrapper,
  SubHeadingWrapper,
  FilterAll,
  SearchWrapper,
  SideBarContainer,
  SideBarWrapper,
} from "./styles";
import { filterAndMapToProposals } from "./utils";
import ProposalsIcon from "../../assets/icons/Proposals.svg";
import Files from "../../assets/icons/Files.svg";
import { DocumentType } from "../../components/Document";
import { PolicyComparisonWizardContainer } from "../../components/PolicyComparisonWizard/styles";
import SelectComparison from "../../components/PolicyComparisonWizard/pages/SelectComparison";
import {
  analyzePolicy,
  combineDocuments,
  updateCoverageLines,
} from "../../requests";
import CardButton from "../../components/CardButton";
import FileLarge from "../../assets/icons/File_Large.svg";
import Presentation from "../../assets/icons/Presentation.svg";
import ScrollableCardOptions from "../../components/ScrollableCardOptions";
import { ScrollableCardOption } from "../../components/ScrollableCardOptions/types";
import Home from "../../assets/icons/Home.svg";
import FileUploadButton from "../../components/FileUploadButton";
import TextInput from "../../components/TextInput";
import Search from "../../assets/icons/Search.svg";

const Dashboard: React.FC<DashboardProps> = ({
  docList,
  reports,
  retrieveDocuments,
  clientId,
  clients = [],
  setClientId,
  setCreateClientModalOpen,
  setSettingsModalOpen,
  onHome,
  onDocumentView,
  createProposalModalOpen,
  setCreateProposalModalOpen,
  comparePolicyModalOpen,
  setComparePolicyModalOpen,
  isLoading,
  onCreateProposal,
  onCreateComparison,
  cookie,
  handleFileChange,
  successfulUploads,
  addLinesModalOpen,
  setAddLinesModalOpen,
  setSuccessfulUploads,
  agencyId,
  showSummary,
  setShowSummary,
  filter,
  setFilter
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(["user-id"]);
  const [filteredDocuments, setFilteredDocuments] = useState<
    DocumentType[] | undefined
  >([]);
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [comparisons, setComparisons] = useState<ScrollableCardOption[]>([]);
  const [proposals, setProposals] = useState<ScrollableCardOption[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [uploadsIndex, setUploadsIndex] = useState<number>(0);

  const [filteredComparisons, setFilteredComparisons] = useState<ScrollableCardOption[]>([]);
  const [filteredProposals, setFilteredProposals] = useState<ScrollableCardOption[]>([]);

  const sideBarOptions = [
    {
      handeClick: () => handleClickDashboard(),
      text: "Dashboard",
      icon: Home,
      type: PillType.POLICY,
    },
    {
      handeClick: () => handleClickComparison(),
      text: "Comparisons",
      icon: Files,
      type: PillType.COMPARISON,
    },
    {
      handeClick: () => handleClickProposal(),
      text: "Proposals",
      icon: ProposalsIcon,
      type: PillType.PROPOSAL,
    },
  ];

  const handleClickDashboard = () => {
    setShowSummary(true);
    setFilter(PillType.POLICY);
    setSearchTerm("");
  };

  const handleClickComparison = () => {
    setShowSummary(false);
    setFilter(PillType.COMPARISON);
    setSearchTerm("");
  };

  const handleClickProposal = () => {
    setShowSummary(false);
    setFilter(PillType.PROPOSAL);
    setSearchTerm("");
  };

  useEffect(() => {
    if (!docList) return;
    const mappedProposals = docList
      .filter((doc) => doc.category.toLowerCase() === "proposal")
      .sort((a, b) => {
        const dateA = new Date(a.createdAt ?? "").getTime();
        const dateB = new Date(b.createdAt ?? "").getTime();
        return dateB - dateA;
      })
      .map((doc) => ({
        type: "Policy",
        header: doc.name,
        linkedFiles: (
          <LinkedDocuments
            row={doc}
            documents={docList}
            onDocumentView={onDocumentView}
          />
        ),
        handleClick: () => {
          onDocumentView(doc);
          window.scrollTo(0, 0);
        },
        loading: doc.status == "analyzing",
      }));

    const mappedComparisons = docList
      .filter((doc) => doc.category.toLowerCase() === "comparison")
      .sort((a, b) => {
        const dateA = new Date(a.createdAt ?? "").getTime();
        const dateB = new Date(b.createdAt ?? "").getTime();
        return dateB - dateA;
      })
      .map((doc) => ({
        type: "Comparison",
        header: doc.name,
        linkedFiles: (
          <LinkedDocuments
            row={doc}
            documents={docList}
            onDocumentView={onDocumentView}
          />
        ),
        handleClick: () => {
          onDocumentView(doc);
          window.scrollTo(0, 0);
        },
        loading: doc.status == "analyzing" && !doc.websiteReady,
        differencesCount: doc.differencesCount,
      }));

    setComparisons(mappedComparisons);
    setProposals(mappedProposals);
  }, [docList]);

  useEffect(() => {
    setFilteredComparisons(comparisons.filter(c => c.header.toLowerCase().includes(searchTerm)));
    setFilteredProposals(proposals.filter(c => c.header.toLowerCase().includes(searchTerm)));
  }, [comparisons, proposals, searchTerm])

  useEffect(() => {
    if (docList) {
      const filteredByType =
        filter && filter != PillType.ALL
          ? docList.filter((document) => {
              return filter
                ? document.category === filter.toLocaleLowerCase()
                : false;
            })
          : docList.filter((document) => document.category !== "endorsement");
      const filteredList = filteredByType.filter((document) =>
        document.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredDocuments(filteredList);
    }
  }, [searchTerm, docList, filter]);

  const handleCreateProposalModalClose = () => {
    setCreateProposalModalOpen(false);
    setSuccessfulUploads([]);
  };
  const handleComparePolicyModalClose = () => {
    setComparePolicyModalOpen(false);
    setSuccessfulUploads([]);
  };
  const handleSearch = (term: string) => setSearchTerm(term);

  const handleSelectLines = async (selected: string[]) => {
    setLoading(true);
 
    let newChosenIndex = uploadsIndex;
    let filteredSuccessfulUploads = successfulUploads.filter(s => {
      const doc = docList?.find(d => d.instanceId == s);
      return (doc?.category == "policy");
    })
    await combineDocuments(cookies['user-id'], filteredSuccessfulUploads[newChosenIndex] ?? "");
    await updateCoverageLines(cookies['user-id'], filteredSuccessfulUploads[newChosenIndex] ?? "", selected);
    analyzePolicy(cookies['user-id'], "", filteredSuccessfulUploads[newChosenIndex] ?? "", "internal", true);

    if (newChosenIndex == filteredSuccessfulUploads.length - 1) {
      retrieveDocuments();
      setSuccessfulUploads([]);
      setAddLinesModalOpen(false);
    } else {
      setUploadsIndex(newChosenIndex + 1);
      await new Promise(r => setTimeout(r, 150));
    }

    setSelected([]);
    setLoading(false);
  }

  return (
    <>
      <PageContentWrapper sidebarOpen={sidebarOpen}>
        <AppHeader
          clientId={clientId}
          setClientId={setClientId}
          clients={clients}
          setCreateClientModalOpen={setCreateClientModalOpen}
          setSettingsModalOpen={setSettingsModalOpen}
          onSearch={handleSearch}
          onHome={onHome}
          handleCreateProposal={() => setCreateProposalModalOpen(true)}
          handleComparePolicy={() => setComparePolicyModalOpen(true)}
          sticky
        />
        <PageContentHorizontalWrapper>
          <SideBarWrapper>
            <SideBarContainer>
              <SideBar options={sideBarOptions} selected={filter} />
            </SideBarContainer>
          </SideBarWrapper>
          <ContentWrapper>
            {showSummary && (
              <>
                <HeaderWrapper>
                  <Header>Welcome to&nbsp;</Header>
                  <Coverflow>Coverflow</Coverflow>
                </HeaderWrapper>
                <SubText>Helping you sell insurance faster.</SubText>
                <SubHeadingWrapper>
                  <SubHeading>What would you like to do?</SubHeading>
                </SubHeadingWrapper>
                <CardWrapper>
                  <CardButton
                    buttonIcon={FileLarge}
                    header="Compare Policies"
                    content="Coverflow’s AI technology scans and evaluates both policies and highlights differences"
                    handleClick={() => setComparePolicyModalOpen(true)}
                  />
                  <CardButton
                    buttonIcon={Presentation}
                    header="Create Proposal"
                    content="Coverflow’s AI generates a clean, professional policy presentation for your client"
                    handleClick={() => setCreateProposalModalOpen(true)}
                  />
                </CardWrapper>
              </>
            )}
            {!isLoading && showSummary && (
              <>
                {comparisons.length > 0 && (
                  <ComparisonsWrapper>
                    <SubHeadingWrapper>
                      <SubHeading>Your Comparisons</SubHeading>
                      <FilterAll onClick={handleClickComparison}>
                        view all
                      </FilterAll>
                    </SubHeadingWrapper>
                    <ScrollableCardOptions options={filteredComparisons} />
                  </ComparisonsWrapper>
                )}
                {proposals.length > 0 && (
                  <ComparisonsWrapper>
                    <SubHeadingWrapper>
                      <SubHeading>Your Proposals</SubHeading>
                      <FilterAll onClick={handleClickProposal}>
                        view all
                      </FilterAll>
                    </SubHeadingWrapper>
                    <ScrollableCardOptions options={filteredProposals} />
                  </ComparisonsWrapper>
                )}
              </>
            )}
            <SubHeadingWrapper>
              {filter === PillType.POLICY && <SubHeading>All Files</SubHeading>}
              {filter === PillType.COMPARISON && (
                <SubHeading>Your Comparisons</SubHeading>
              )}
              {filter === PillType.PROPOSAL && (
                <SubHeading>Your Proposals</SubHeading>
              )}
              { filter === PillType.POLICY && <FileUploadButton handleFileChange={handleFileChange} /> }
            </SubHeadingWrapper>
            <SearchWrapper>
              <TextInput
                icon={Search}
                placeholder="Search"
                $grow
                onChangeCallback={handleSearch}
              />
            </SearchWrapper>
            <DocumentTable
              docList={docList}
              reports={reports}
              documents={filteredDocuments}
              retrieveDocuments={retrieveDocuments}
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
              onDocumentView={onDocumentView}
              isLoading={isLoading}
              cookie={cookie}
              handleFileChange={handleFileChange}
              filtered={!!filter || searchTerm != ""}
            />
          </ContentWrapper>
        </PageContentHorizontalWrapper>
      </PageContentWrapper>

      {createProposalModalOpen && (
        <Modal onClick={handleCreateProposalModalClose}>
          <CreateProposal
            alreadySelected={selectedDocuments}
            proposals={docList || []}
            close={handleCreateProposalModalClose}
            onSubmit={onCreateProposal}
            handleFileChange={handleFileChange}
            agencyId={agencyId}
            successfulUploads={successfulUploads}
          />
        </Modal>
      )}

      {comparePolicyModalOpen && (
        <Modal onClick={handleComparePolicyModalClose}>
          <PolicyComparisonWizard
            handleFileChange={handleFileChange}
            alreadySelected={selectedDocuments}
            rows={docList || []}
            handleCancel={handleComparePolicyModalClose}
            onSubmit={onCreateComparison}
            successfulUploads={successfulUploads}
            agencyId={agencyId}
          />
        </Modal>
      )}

      {addLinesModalOpen && (
        <Modal onClick={() => {}}>
          <PolicyComparisonWizardContainer>
            <SelectComparison
              handleFileChange={handleFileChange}
              handleCancel={() => setAddLinesModalOpen(false)}
              policyName={
                docList?.find((d) => successfulUploads && d.instanceId == successfulUploads[uploadsIndex])?.name ??
                undefined
              }
              policyId={
                docList?.find((d) => successfulUploads && d.instanceId == successfulUploads[uploadsIndex])?.instanceId ??
                undefined
              }
              onComplete={handleSelectLines}
              loading={loading}
              selected={selected}
              setSelected={setSelected}
              documents={docList ?? []}
              singlePolicy
            />
          </PolicyComparisonWizardContainer>
        </Modal>
      )}
    </>
  );
};

export default Dashboard;
